import Global from "./global";
import moment from 'moment';
export default {
  /**
   * function for storing the data in localStorage
   */
  setData: (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  },

  /**
   * function for getting the data from localStorage
   */
  getData: key => JSON.parse(localStorage.getItem(key)),

  /**
   * function for clearing particular key from localStorage
   */
  clearData: (key) => {
    localStorage.removeItem(key);
  },

  /**
   * function for clearing all data from localStorage
   */
  clearAllData: () => {
    localStorage.clear();
  },

  /**
   * function for checking the user input.
   */
  checkInput(name, value) {
    let isValid = true;
    switch (name) {
      case "name":
        const alphaRegex = Global.regex.alpha;
        isValid = alphaRegex.test(value);
        break;
      case "phoneNo":
        const phoneNoRegex = Global.regex.phoneNumber;
        isValid = phoneNoRegex.test(value);
        break;
      case "otp":
        const numericRegex = Global.regex.numeric;
        isValid = numericRegex.test(value);
        break;
      default:
    }
    return isValid;
  },

  /**
    * function for getting the user type info.
   */
  getUserTypeInfo(userType) {
    let userInfo = {
      admin: false,
      staff: false,
      superUser: false
    }
    switch (userType) {
      case 'Staff':
        userInfo.staff = true;
        break;
      case 'Admin':
        userInfo.staff = true;
        userInfo.admin = true;
        break;
      case 'Super User':
        userInfo.staff = true;
        userInfo.admin = true;
        userInfo.superUser = true;
        break;
      default:
    }
    return userInfo;
  },

  /**
   * function for getting the values object from model
   */
  getModelValues(model) {
    let data = {};
    for (let keys in model) {
      data[keys] = model[keys].value;
    }
    return data;
  },

  /**
   * function to convert unix timestamp to local date
   */
  convertUnixTimestampToDate(timestamp) {
    let convertedDate = moment.unix(timestamp).utc().format('MM/DD/YYYY')
    return convertedDate;
  },

  getFormattedDate: (datetime) => {
    let offset = moment.parseZone(datetime).utcOffset();
    let date = moment.utc(datetime).utcOffset(offset).format('MM/DD/YYYY')
    return date;
   },


  getDate: (timestamp) => {
    let monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let datetime = new Date(timestamp*1000).toISOString()
    var parts = datetime.match(/(\d+)/g);
    var date = new Date(parts[0], parts[1]-1, parts[2]);
    let month = monthArray[date.getMonth()]
    return ('0' + date.getDate()).slice(-2) + '/' +  month  + '/' + date.getFullYear();
   },

    /**
   * function for checking license status is active or expired based on license end date.
  */
  compareDate: (timestamp) => {
    let licenseEndDate = new Date(timestamp * 1000);
    let todayTimestamp = Math.floor(Date.now() / 1000)
    let todayDate = new Date(todayTimestamp * 1000)
    let isActive = false;
    if (todayDate < licenseEndDate)
        isActive = true;
    else if (todayDate > licenseEndDate)
        isActive = false;
    else
        isActive = true;
    return isActive;
   },

  /**
   * function for setting the token into the localStorage.
  */
  setToken(tokenType, token) {
    // Getting the user id and set it to the localStorage.
    if (tokenType === 'accessToken') {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      this.setData('userEmail', JSON.parse(window.atob(base64)).email);
    }
    this.setData(tokenType, token);
  },

  /**
   * function for getting the token from the localStorage.
   */
  getToken(key) {
    const data = this.getData(key);
    if (data === null) {
      return '';
    }
    return data;
  },

  exportDataHeaderWithFilter(data) {
    let exportData = data.map(({ id, active, state, admin, city, country, superUser, staff, ...keepAttrs }) => keepAttrs);
    exportData = exportData.map(data => {
      return {
        ...data,
        expiryDate: this.convertUnixTimestampToDate(data.expiryDate),
        createdDate: this.convertUnixTimestampToDate(data.createdDate)
      }
    })
    // exportData.unshift({ 'firstName': 'First name', 'lastName': 'Last name', 'company': 'Company', 'email': 'Email', 'phoneNumber': 'Phone number', 'cloudImageProviders': 'Environment', 'reportingDB': 'Reporting database','createdDate': 'Registration Date', 'expiryDate': 'Expiration Date' });
    exportData.unshift({ 'company': 'Company', 'expiryDate': 'Expiration Date', 'createdDate': 'Registration Date', 'cloudImageProviders': 'Environment', 'reportingDB': 'Reporting Database' });

    return exportData;
  },

  stringToBoolean(string) {
    if (string) {
      switch (string.toLowerCase().trim()) {
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(string);
      }
    }
    return false;
  },

  exportToCsv(filename, data) {
    var processRow = function (row) {
      var finalVal = "";
      let firstKey = true;
      for (var key in row) {
        var innerValue = row[key] === null ? "" : row[key].toString();
        if (row[key] instanceof Date) {
          innerValue = row[key].toLocaleString();
        }
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
        if (!firstKey) finalVal += ",";
        firstKey = false;
        finalVal += result;
      }
      return finalVal + "\n";
    };

    var csvFile = "";
    data = this.exportDataHeaderWithFilter(data);

    for (var i = 0; i < data.length; i++) {
      csvFile += processRow(data[i]);
    }

    var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },

  checkUploadedInvoiceType(type) {
    const fileType = type
      .split("/")
      .pop()
      .toLowerCase();
    if (Global.uploadedInvoiceType.indexOf(fileType) !== -1) {
      return { upload: true };
    }
    return {
      upload: false,
      error: "Only pdf file is allowed"
    };
  },

  isValidEmail: (email) => {
    let emailRegex = new RegExp(/^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line
    if (!emailRegex.test(email)) 
         return false
     else
         return true;
},
compareDate: (datetime) => {
  let licenseEndDate = new Date(datetime)
  var todayDate = new Date();
  let isActive = false;
  if (todayDate < licenseEndDate)
      isActive = true;
  else if (todayDate > licenseEndDate)
      isActive = false;
  else
      isActive = true;
  return isActive;
 },

 resizeFileName: (file_name) => {
  let lastDotPosition = file_name.lastIndexOf(".");
  let file_ext = file_name.substring(file_name.lastIndexOf(".") + 1);
  let new_file_name =
      file_name.substring(0, 38) +
      "..." +
      file_name.substring(lastDotPosition - 3, lastDotPosition) +
      "." +
      file_ext;
  return new_file_name;
},
};

import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image/Image';
import appLogo from "../../../assets/images/izenda-logo.png";

const Logo = (props) => (
    <div className={props.class}>
        <Image image={appLogo} alt={props.alt} imgClass={props.imgClass} />
    </div>
);

Logo.propTypes = {
    alt: PropTypes.string,
    imgClass: PropTypes.string,
    class: PropTypes.string
}

export default Logo;
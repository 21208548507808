import React, { Component, Suspense } from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import Toast from "../../UI/Toast/Toast";
import Button from "../../UI/Button/Button";
import { Icon } from "react-icons-kit";
import { users } from "react-icons-kit/feather/users";
import { book } from "react-icons-kit/feather/book";
import { home } from "react-icons-kit/feather/home";
import { award } from "react-icons-kit/feather/award";
import { creditCard } from "react-icons-kit/feather/creditCard";
import { arrowUpCircle } from "react-icons-kit/feather/arrowUpCircle";
import { arrowDownCircle } from "react-icons-kit/feather/arrowDownCircle";
import { user } from "react-icons-kit/feather/user";
import backIcon from "../../../assets/images/back.png";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "./Account.scss";
import Members from "../../../containers/UserManagement/Members";
import EditUser from "../../../containers/UserManagement/EditUser";
const Dialog = React.lazy(() => import("../../UI/Dialog/Dialog"));
// const EditUser = React.lazy(() =>
//   import("../../../containers/UserManagement/EditUser")
// );
const Invoices = React.lazy(() =>
  import("../../../containers/UserManagement/Invoices")
);
// const Members = React.lazy(() =>
//   import("../../../containers/UserManagement/Members")
// );

const License = React.lazy(() =>
  import("../../../containers/UserManagement/License")
);

const Downloads = React.lazy(() =>
  import("../../../containers/UserManagement/Downloads")
);

class AccountComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      responseTitle: "",
      responseMessage: "",
      expanded: true,
    };
    this.navigateBack = this.navigateBack.bind(this);
    this.showToast = this.showToast.bind(this);
    this.setToastData = this.setToastData.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  navigateBack() {
    this.props.sharedStore.clearLicenseData();
    this.props.history.push("/dashboard");
  }

  setToastData(toastType, toastMessage) {
    this.setState(
      {
        responseType: toastType,
        responseTitle: toastType,
        responseMessage: toastMessage,
      },
      () => this.showToast()
    );
  }

  showToast() {
    const { responseType, responseTitle, responseMessage } = this.state;
    this.growl.show({
      severity: responseType,
      summary: responseTitle,
      detail: responseMessage,
      life: 2200,
    });
  }

  componentDidMount() {
    // this.props.sharedStore.getLicInfo({ id: this.props.sharedStore.companyId });
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    if (window.innerWidth < 769) {
      this.setState({
        expanded: false,
      });
    } else {
      this.setState({
        expanded: true,
      });
    }
  }

  render() {
    let { state } = this.props.history.location;
    const { companySeriesType } = this.props.sharedStore;
    let customerId = state && state.customerId ? state.customerId : null;
    return (
      <div className="account-wrapper">
        <Tab.Container id="account-tabs" defaultActiveKey="members">
          <div className="custom_wrapper">
            <SideNav
              expanded={this.state.expanded}
              onToggle="doNothing"
              id="mainSideNav"
              style={{ position: "relative", marginLeft: "10px" }}
            >
              <SideNav.Nav defaultSelected="members">
                <NavItem id="home" eventKey="home">
                  <NavIcon>
                    <Nav.Link className="sideNavLink" eventKey="home">
                      <Icon className="sideNavIcon" icon={home} />
                    </Nav.Link>
                  </NavIcon>
                  <NavText style={{ fontSize: "10px !important" }}>
                    HOME
                  </NavText>
                </NavItem>

                <NavItem eventKey="account" id="account">
                  <NavIcon>
                    <Nav.Link className="sideNavLink" eventKey="account">
                      <Icon className="sideNavIcon" icon={user} />
                    </Nav.Link>
                  </NavIcon>
                  <NavText>ACCOUNT</NavText>
                </NavItem>

                <NavItem eventKey="members" id="members">
                  <NavIcon>
                    <Nav.Link className="sideNavLink" eventKey="members">
                      <Icon className="sideNavIcon" icon={users} />
                    </Nav.Link>
                  </NavIcon>
                  <NavText>MEMBERS</NavText>
                </NavItem>

                <NavItem eventKey="invoice" id="invoice">
                  <NavIcon>
                    <Nav.Link className="sideNavLink" eventKey="invoice">
                      <Icon className="sideNavIcon" icon={creditCard} />
                    </Nav.Link>
                  </NavIcon>
                  <NavText>INVOICES</NavText>
                </NavItem>
                {companySeriesType != "0" ? (
                  <NavItem eventKey="licenses" id="licenses">
                    <NavIcon>
                      <Nav.Link className="sideNavLink" eventKey="licenses">
                        <Icon className="sideNavIcon" icon={award} />
                      </Nav.Link>
                    </NavIcon>
                    <NavText>LICENSES</NavText>
                  </NavItem>
                ) : null}
                <NavItem eventKey="downloads" id="downloads">
                  <NavIcon>
                    <Nav.Link className="sideNavLink" eventKey="downloads">
                      <Icon className="sideNavIcon" icon={arrowDownCircle} />
                    </Nav.Link>
                  </NavIcon>
                  <NavText>DOWNLOADS</NavText>
                </NavItem>

                {/* <NavItem eventKey="documentation" id="documentation">
                  <NavIcon>
                    <Nav.Link className="sideNavLink" eventKey="documentation">
                      <Icon className="sideNavIcon" icon={book} />
                    </Nav.Link>
                  </NavIcon>
                  <NavText>DOCUMENTATION</NavText>
                </NavItem> */}

                {/* <NavItem eventKey="upgrade" id="upgrade">
                  <NavIcon >
                    <Nav.Link className="sideNavLink" eventKey="upgrade">
                      <Icon className="sideNavIcon" icon={arrowUpCircle} />
                    </Nav.Link>
                  </NavIcon>
                  <NavText>UPGRADE</NavText>
                </NavItem> */}
              </SideNav.Nav>
            </SideNav>
            <Col id="accountCol">
              <Tab.Content className="h-100">
                <Tab.Pane
                  eventKey="home"
                  className="rounded-tab-content"
                ></Tab.Pane>

                <Tab.Pane eventKey="account" className="rounded-tab-content">
                  <div className="top-btn-wrapper">
                    <Button
                      btnVariant="link"
                      btnClass="decoration-none"
                      clickHandler={this.navigateBack}
                    >
                      <img src={backIcon} className="back-img" alt="back" />
                      Back
                    </Button>
                  </div>
                  {/* <Suspense fallback=""> */}
                  <EditUser
                    userInfo={customerId}
                    navigateToDashboard={this.navigateBack}
                    setToastData={this.setToastData}
                  />
                  {/* </Suspense> */}
                </Tab.Pane>

                <Tab.Pane eventKey="members">
                  {/* <Suspense fallback=""> */}
                  <Members
                    userInfo={customerId}
                    navigateToDashboard={this.navigateBack}
                    setToastData={this.setToastData}
                  />
                  {/* </Suspense> */}
                </Tab.Pane>

                <Tab.Pane eventKey="invoice">
                  <Suspense fallback="">
                    <Invoices
                      userInfo={customerId}
                      navigateToDashboard={this.navigateBack}
                      setToastData={this.setToastData}
                    />
                  </Suspense>
                </Tab.Pane>

                <Tab.Pane eventKey="licenses" className="rounded-tab-content">
                  <Suspense fallback="">
                    <License
                      userInfo={customerId}
                      navigateToDashboard={this.navigateBack}
                      setToastData={this.setToastData}
                    />
                  </Suspense>
                </Tab.Pane>

                <Tab.Pane eventKey="downloads" className="rounded-tab-content">
                  <Suspense fallback="">
                    <Downloads />
                  </Suspense>
                </Tab.Pane>

                <Tab.Pane
                  eventKey="documentation"
                  className="rounded-tab-content"
                ></Tab.Pane>

                <Tab.Pane
                  eventKey="upgrade"
                  className="rounded-tab-content"
                ></Tab.Pane>
              </Tab.Content>
            </Col>
          </div>
        </Tab.Container>
        {/* Error, Success Message Taost */}
        <Toast toastRef={(el) => (this.growl = el)} />
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
}

export default AccountComponent;

import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import Button from "../../UI/Button/Button";
import InputField from "../../UI/InputField/InputField";
import SelectField from "../../UI/SelectField/SelectField";
import Utility from "../../../common/utility";
import errorMessage from "../../../common/errorMessages";
import CustomerModel from "../../../models/CustomerModel";
import "./EditUser.scss";
import Toggle from "../../../components/UI/Toggle/Toogle.js";

class EditUserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: new CustomerModel(),
      toggleText: this.props.sharedStore.numberOfLicenses.creation_allowed
        ? "ON"
        : "OFF",
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    if (!this.props.userInfo) {
      this.props.navigateToDashboard();
    }
    this.props.userStore.getDropdownLists();
    this.getEditableUserDate();
  }

  getEditableUserDate() {
    let params = {
      id: this.props.userInfo,
    };

    this.props.userStore
      .getEditUserInfo(params)
      .then((res) => {
        this.props.sharedStore.hideLoader();
        if (res.responseCode === 200) {
          let form = this.state.form;
          form.setUserData(this.props.userStore.getUserData(res.data));
          form.reportingDB.value = form.reportingDB.value.split(", ");
          form.reportingDB.value = form.reportingDB.value.filter(
            (data) => data !== ""
          );
          this.setState({ form });
        } else {
          console.log(res.message);
        }
      })
      .catch((err) => {
        this.props.sharedStore.hideLoader();
        console.log("err", err);
      });
  }

  onInputChange(e) {
    let valid = Utility.checkInput(e.target.name, e.target.value);
    if (valid) {
      let form = this.state.form;
      form[e.target.name].value = e.target.value;
      form[e.target.name].error = false;
      form[e.target.name].errorMessage = "";
      this.setState({ form });
    }
  }

  checkFormValidation() {
    let validated = this.state.form.validation();
    if (validated.isValid) {
      return true;
    } else {
      this.setState({ form: validated.form });
    }
  }

  onFormSubmit(event) {
    event.preventDefault();
    if (this.checkFormValidation()) {
      // Call the edit user api.
      let form = this.state.form;
      const params = form.getParams();
      // this.props.sharedStore.showLoader();
      this.props.userStore
        .editUser(params)
        .then((res) => {
          this.props.sharedStore.hideLoader();
          if (res.responseCode === 200) {
            let disableEle = document.querySelectorAll(".select_disableTag");
            for (var i = 0; i < disableEle.length; i++) {
              disableEle[i].classList.add("disable_feild");
            }
            document.getElementById("accountSave").classList.add("hidebtn");
            document.getElementById("accountEdit").classList.remove("hidebtn");
            this.props.sharedStore.setCompanySeriesType(
              form.companySeries.value
            );
            this.props.setToastData("success", res.message);
          } else {
            this.props.setToastData("error", res.message);
          }
        })
        .catch((err) => {
          this.props.sharedStore.hideLoader();
          console.log("err", err);
          this.props.setToastData("error", errorMessage.networkError);
        });
    }
  }

  cancelEditform = (event) => {
    let disableEle = document.querySelectorAll(".select_disableTag");
    let data_action = event.target.attributes.getNamedItem("data-action").value;
    if (data_action == "cancel") {
      for (var i = 0; i < disableEle.length; i++) {
        disableEle[i].classList.add("disable_feild");
      }
      document.getElementById("accountSave").classList.add("hidebtn");
      document.getElementById("accountEdit").classList.remove("hidebtn");
    } else {
      for (var i = 0; i < disableEle.length; i++) {
        disableEle[i].classList.remove("disable_feild");
      }
      document.getElementById("accountSave").classList.remove("hidebtn");
      document.getElementById("accountEdit").classList.add("hidebtn");
    }
  };
  handleToggle = () => {
    if (this.props.sharedStore.creationAllowed) {
      this.toggleOff();
    } else {
      this.toggleOn();
    }
  };
  toggleOff = () => {
    const data = {
      creation_allowed: false,
      company_id: this.props.sharedStore.companyId,
    };
    this.props.sharedStore.putSubLicenseAllowance(data);
    this.setState({
      toggleText: "OFF",
    });
  };

  toggleOn = () => {
    const data = {
      creation_allowed: true,
      company_id: this.props.sharedStore.companyId,
    };
    this.props.sharedStore.putSubLicenseAllowance(data);
    this.setState({
      toggleText: "ON",
    });
  };

  render() {
    const { form } = this.state;
    let {
      environmentList,
      reportingDSList,
      licenseSeriesList,
    } = this.props.userStore;
    return (
      <div className="edit-user-wrapper">
        <div className="accountHeader">
          {form.company.value ? form.company.value : "Company"}'s &nbsp;Account
          Information
        </div>

        <Row className="mt-2" id="accountContainer">
          <Col xs={12} sm={12} md={6} className="accountField">
            <InputField
              label="License Start Date"
              type="text"
              className=""
              name="registrationDate"
              placeholder=""
              onChange={this.onInputChange}
              disabled={form.registrationDate.readOnly}
              value={
                form.registrationDate.value
                  ? Utility.convertUnixTimestampToDate(
                      form.registrationDate.value
                    )
                  : ""
              }
              // value={Utility.convertUnixTimestampToDate(form.registrationDate.value)}
              error={form.registrationDate.error}
              errorMessage={form.registrationDate.errorMessage}
            />
          </Col>

          <Col xs={12} sm={12} md={6} className="accountField">
            <InputField
              label="License Expiration Date"
              type="text"
              className="expirationDate"
              placeholder=""
              onChange={this.onInputChange}
              disabled={form.expirationDate.readOnly}
              value={
                form.expirationDate.value
                  ? Utility.convertUnixTimestampToDate(
                      form.expirationDate.value
                    )
                  : ""
              }
              // value={Utility.convertUnixTimestampToDate(form.expirationDate.value)}
              error={form.expirationDate.error}
              errorMessage={form.expirationDate.errorMessage}
            />
          </Col>
          <Col xs={12} sm={12} md={6} className="accountField">
            <SelectField
              label="License Type"
              fieldClass="disable_feild select_disableTag"
              name="companySeries"
              onChange={this.onInputChange}
              selectOptions={licenseSeriesList.slice()}
              value={form.companySeries.value}
              error={form.companySeries.error}
              errorMessage={form.companySeries.errorMessage}
            />
          </Col>
          <Col xs={12} sm={12} md={6} className="account_generateBtn">
            <div className="btn btn-primary">
              Generate New Sub-Client License
            </div>
            <span className="account_tog_sub">
              <Toggle
                value={this.props.sharedStore.numberOfLicenses.creation_allowed}
                onChange={this.handleToggle}
              />
              <span className="toggletxt">{this.state.toggleText}</span>
            </span>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            className="text-center mt-4 hidebtn"
            id="accountSave"
          >
            <div
              className="app-btn account_cancleBtn"
              data-action="cancel"
              onClick={this.cancelEditform}
            >
              Cancel
            </div>
            <Button
              btnVariant="primary"
              btnClass="app-btn account_saveBtn"
              id="accountBtn"
              clickHandler={this.onFormSubmit}
            >
              Save Changes
            </Button>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            className="text-center mt-4"
            id="accountEdit"
          >
            <div
              className="app-btn account_editBtn"
              id="editbtn"
              data-action="edit"
              onClick={this.cancelEditform}
            >
              Edit
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EditUserComponent;

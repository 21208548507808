import React, { Component } from "react";
import "./LoaderComponent.scss";
import Modal from "react-modal";
import { FadeLoader } from "react-spinners";

class LoaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    Modal.setAppElement("body");
  }

  render() {
    const { isShowLoader } = this.props.sharedStore;
    return (
      <React.Fragment>
        {isShowLoader ? (
          <Modal
            isOpen={isShowLoader}
            onRequestClose={() => console.log("")}
            className="loader-modal"
            shouldCloseOnOverlayClick={false}
          >
            <FadeLoader
              sizeUnit={"px"}
              size={200}
              color={"#fff"}
              loading={true}
            />
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default LoaderComponent;

/*
 * @Author: Pulkit
 * @Date: 2019-03-17 11:25:10
 * @Last Modified by: Pulkit
 */
import ErrorMessages from "../common/errorMessages";
import Global from "../common/global";
import rootStore from "../stores/RootStore";
import Utility from "../common/utility";

export default class CustomerModel {
  constructor() {
    this.customerId = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.active = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.staff = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.admin = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.superUser = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.country = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.state = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.city = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.name = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.firstName = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.lastName = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.company = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.email = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.phoneNo = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.registrationDate = {
      value: "",
      readOnly: true,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.expirationDate = {
      value: "",
      readOnly: true,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.environment = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.reportingDB = {
      value: [],
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.token = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.licenseMode = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.license = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.parentCompany = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.subCompany = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.token_setting_id = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };

    this.role = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.companyId = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.companySeries = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    this.is_custom_payment_enabled = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: "",
    };
    
  }

  validation() {
    let isValid = true;
    // Validaton for series
    if (!this.company.value) {
      isValid = false;
      this.companySeries.error = true;
      this.companySeries.errorMessage = ErrorMessages.companyValidation;
    }
    return {
      isValid: isValid,
      form: this,
    };
  }

  setUserData(data) {
    this.customerId.value = data.customerId;
    this.name.value = data.name;
    this.firstName.value = data.firstName;
    this.lastName.value = data.lastName;
    this.country.value = data.country || "";
    this.state.value = data.state || "";
    this.city.value = data.city || "";
    rootStore.sharedStore.setStateList(data.country);
    rootStore.sharedStore.setCityList(data.state);
    this.company.value = data.company;
    this.email.value = data.email;
    this.phoneNo.value = data.phoneNo;
    this.registrationDate.value = data.registrationDate;
    this.expirationDate.value = data.expirationDate;
    this.environment.value = data.environment;
    this.reportingDB.value = data.reportingDB;
    this.active.value = data.active;
    this.staff.value = data.staff;
    this.admin.value = data.admin;
    this.superUser.value = data.superUser;
    this.token.value = data.token;
    this.licenseMode.value = data.licenseMode;
    this.token_setting_id.value = data.token_setting_id;
    this.license.value = data.license;
    this.parentCompany.value = data.parentCompany;
    this.subCompany.value = data.subCompany;
    this.role.value = data.role;
    this.companyId.value = data.companyId;
    this.companySeries.value = data.companySeries;
    this.is_custom_payment_enabled.value = data.is_custom_payment_enabled;
    return this;
  }

  getParams() {
    const sharedStore = Utility.getData('sharedStore');
    const companyId = sharedStore ? sharedStore.companyId : "";
    return {
      company: companyId,
      companySeries : parseInt(this.companySeries.value),
    };
  }

  setName(val) {
    this.name.value = val;
    this.name.error = false;
  }

  getName(val) {
    return this.name;
  }
}

import React, { Component } from "react";
import { Switch } from "react-router-dom";
import Header from "../../containers/shared/Header";
import FooterComponent from "../shared/FooterComponent/FooterComponent";
import Loader from "../../containers/shared/Loader";
import routes from "../../routes/routes";
import CustomRoute from "../../routes/CustomRoute";
import "./App.scss";

class App extends Component {
  render() {
    
    return (
      <div className="App">
        <Header />
        <Loader />
        <div className="main-section">
          <Switch>
            {routes.map((route, i) => (
              <CustomRoute 
              key={i} {...route} />
            ))}
          </Switch>
        </div>
        <FooterComponent />
      </div>
    );
  }
}

export default App;

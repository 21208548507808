import React from "react";
import { Modal } from "react-bootstrap";
import "./AppModal.scss";

const AppModal = props => {
  return (
    <Modal
      show={props.showModal}
      onHide={props.modalHandler}
      dialogClassName={props.dialogCustomClass}
      centered={props.modalCeneterd}
      size={props.modalSize}
      backdrop={props.modalBackdrop}
    >
      {props.children}
    </Modal>
  );
};

export default AppModal;

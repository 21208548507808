import React, { Component } from "react";
import "./Teams.scss"

class TeamsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="striped-table w-100">
          <thead>
            <tr>
              <th>Member</th>
              <th>Email</th>
              <th>Role</th>
              <th>Created</th>
              {/* <th>Options</th> */}
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </table>
      </div>
    );
  }
}

export default TeamsComponent;
import React from "react";
import { Nav, DropdownButton } from "react-bootstrap";
import { Icon } from 'react-icons-kit';
import {userCircle} from 'react-icons-kit/fa/userCircle'



// import classes from "./Navigation.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import Global from "../../common/global";
const Navigation = props => (
  <div className="ml-auto">
    {props.isAuthenticated ? (
      <Nav as="ul">
        <li>
          <span className="user_logo">
          <Icon className="user_icons" size="25px" icon={userCircle} />
          </span>
          <DropdownButton
            id="user-setting-button"
            title={props.userName}
            className="app-btn user-setting-dropdown"
          >
            {/* <NavigationItem
              navItemClass="nav-link"
              clickHandler={null}
              navtype="button"
              navVariant="link"
            >
              My Profile
            </NavigationItem> */}
            <NavigationItem
              navItemClass="nav-link"
              clickHandler={props.changePasswordHanlder}
              navtype="button"
              navVariant="link"
            >
              Change Password
            </NavigationItem>
            <NavigationItem
              navItemClass="nav-link"
              clickHandler={props.logout}
              navtype="button"
              navVariant="link"
            >
              Logout
            </NavigationItem>
          </DropdownButton>
        </li>
      </Nav>
    ) : null}
  </div>
);

export default Navigation;

/*
 * @Author: Pulkit
 * @Date: 2019-03-17 11:25:10
 * @Last Modified by: Pulkit
 */
import ErrorMessages from "../common/errorMessages";
import Global from "../common/global";
import Utility from "../common/utility";

export default class UserModel {
  constructor() {
    this.userList = [];
    this.firstName = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.lastName = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.email = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.phoneNo = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.company = {
      value: "Izenda",
      readOnly: true,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.userType = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.country = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };

    this.category = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };

    this.state = {
      value: "",
      readOnly: true,
      encrypt: false,
      error: false,
      errorMessage: ""
    };

    this.city = {
      value: "",
      readOnly: true,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.token = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.license = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.parentCompany = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
    this.subCompany = {
      value: "",
      readOnly: false,
      encrypt: false,
      error: false,
      errorMessage: ""
    };
  }

  validation() {
    let isValid = true;

    // Validaton for First Name
    if (this.firstName.value) {
      const firstNameRegex = Global.regex.alpha;
      if (!firstNameRegex.test(this.firstName.value)) {
        isValid = false;
        this.firstName.error = true;
        this.firstName.errorMessage = ErrorMessages.firstNameValidation;
      }
    } else {
      isValid = false;
      this.firstName.error = true;
      this.firstName.errorMessage = ErrorMessages.firstNameRequired;
    }

    // Validaton for Last Name
    if (this.lastName.value) {
      const lastNameRegex = Global.regex.alpha;
      if (!lastNameRegex.test(this.lastName.value)) {
        isValid = false;
        this.lastName.error = true;
        this.lastName.errorMessage = ErrorMessages.lastNameValidation;
      }
    }

    // Validaton for Email
    if (this.email.value) {
      const emailRegex = Global.regex.email;
      if (!emailRegex.test(this.email.value)) {
        isValid = false;
        this.email.error = true;
        this.email.errorMessage = ErrorMessages.emailValidation;
      }
    } else {
      isValid = false;
      this.email.error = true;
      this.email.errorMessage = ErrorMessages.emailRequired;
    }

    
    // Validaton for company
    if (!this.company.value) {
      isValid = false;
      this.company.error = true;
      this.company.errorMessage = ErrorMessages.companyValidation;
    }

    // Validaton for User Type
    if (!this.userType.value) {
      isValid = false;
      this.userType.error = true;
      this.userType.errorMessage = ErrorMessages.userTypeRequired;
    }

    

    return {
      isValid: isValid,
      form: this
    };

  }

  setUserData(data) {
    this.name.value = data.name;
    this.company.value = data.company;
    this.email.value = data.email;
    this.phoneNo.value = data.phone;
    this.environment.value = data.env;
    this.reportingDataSource.value = data.reportingDB;
  }

  getUserParams() {
    const loginStore = Utility.getData('loginStore');
    const loggedUser = loginStore ? loginStore.userEmail : '';
    let userTypeInfo = Utility.getUserTypeInfo(this.userType.value);
    return {
      first_name: this.firstName.value,
      last_name: this.lastName.value,
      company: this.company.value,
      email: this.email.value,
      staff: userTypeInfo.staff,
      admin: userTypeInfo.admin,
      super_user: userTypeInfo.superUser,
      by:loggedUser
    }
  }

  getParams() {
    const loginStore = Utility.getData('loginStore');
    const loggedUser = loginStore ? loginStore.userEmail : '';
    let userTypeInfo = Utility.getUserTypeInfo(this.userType.value);
    return {
      first_name: this.firstName.value,
      last_name: this.lastName.value,
      company: this.company.value,
      email: this.email.value,
      phone_number: this.phoneNo.value,
      country: this.country.value,
      state: this.state.value,
      city: this.city.value,
      staff: userTypeInfo.staff,
      admin: userTypeInfo.admin,
      super_user: userTypeInfo.superUser,
      token: this.token.value,
      license: this.license.value,
      parentCompany: this.parentCompany.value,
      subCompany: this.subCompany.value,
      by:loggedUser
    }
  }

  setName(val) {
    this.name.value = val;
    this.name.error = false;
  }

  getName(val) {
    return this.name;
  }
}

import { observable, action, computed, observe } from "mobx";
import CustomerModel from "../models/CustomerModel";
import UserService from "../services/userService";
import rootStore from "./RootStore";
import Global from "../common/global";
import Utility from "../common/utility";

const selectCompany = {
  value: "",
  displayValue: "Company",
};

const selectEnv = {
  value: "",
  displayValue: "Environment",
};

const initialState = {
  userModel: [],
  companyList: [selectCompany],
  companiesList: [],
  environmentList: [selectEnv],
  licenseSeriesList: [],
  reportingDSList: [],
  companyArr: [],
  totalPages: 0,
  currentPage: 0,
  pageSize: Global.pageSize,
  dataToExport: [],
  selectedCompany: "",
  selectedEnvironment: "",
  searchKeyword: "",
  selectedReportingDS: [],
};

class UserStore {
  constructor() {
    this.load();

    observe(this, (change) => {
      if (!this.isReset) {
        switch (change.name) {
          case "currentPage":
            // call get user api when user click on any page.
            this.getUsers();
            break;
          case "selectedEnvironment":
          case "selectedCompany":
          case "searchKeyword":
          case "selectedReportingDS":
            // call get user api when user search or apply filter.
            if (this.currentPage === 0) {
              this.getUsers();
            } else {
              // Set the current page to 0
              this.currentPage = 0;
            }
            break;
          case "totalUsers":
            // call user api when there is last user on the last page.
            if (
              this.totalUsers &&
              this.totalUsers % this.pageSize === 0 &&
              this.totalPages === this.currentPage
            ) {
              this.currentPage = this.currentPage - 1;
            }
            break;
          default:
        }
      }
    });
  }

  load() {
    let userStoreData = Utility.getData("userStore");
    if (userStoreData) {
      this.selectedCompany = userStoreData.selectedCompany;
      this.selectedEnvironment = userStoreData.selectedEnvironment;
      this.selectedReportingDS = userStoreData.selectedReportingDS;
      this.searchKeyword = userStoreData.searchKeyword;
      this.currentPage = userStoreData.currentPage;
    }
  }

  @observable userModel = initialState.userModel;
  @observable companyList = initialState.companyList;
  @observable companiesList = initialState.companiesList;
  @observable environmentList = initialState.environmentList;
  @observable licenseSeriesList = initialState.licenseSeriesList;
  @observable reportingDSList = initialState.reportingDSList;
  @observable totalPages = initialState.totalPages;
  @observable currentPage = initialState.currentPage;
  @observable totalUsers = initialState.totalUsers;
  @observable pageSize = initialState.pageSize;
  @observable dataToExport = initialState.dataToExport;
  @observable selectedCompany = initialState.selectedCompany;
  @observable selectedEnvironment = initialState.selectedEnvironment;
  @observable searchKeyword = initialState.searchKeyword;
  @observable selectedReportingDS = initialState.selectedReportingDS;
  isReset = false;

  getUserData(userData) {
    return {
      name: `${userData.firstName} ${userData.lastName}`,
      firstName: userData.firstName,
      token_setting_id: userData.token_setting_id,
      licenseMode: userData.licenseMode,
      lastName: userData.lastName,
      company: userData.company,
      email: userData.email,
      phoneNo: userData.phoneNumber,
      registrationDate: userData.createdDate,
      expirationDate: userData.expiryDate,
      environment: userData.cloudImageProviders,
      reportingDB:
        userData.reportingDB && Array.isArray(userData.reportingDB)
          ? userData.reportingDB.join(", ")
          : "",
      customerId: userData.id,
      admin: userData.admin,
      staff: userData.staff,
      superUser: userData.superUser,
      active: userData.active,
      country: userData.country,
      state: userData.state,
      city: userData.city,
      token: userData.token,
      token_setting_id: userData.token_setting_id,
      license: userData.license,
      parentCompany: userData.parentCompany,
      subCompany: userData.subCompany,
      role: userData.role,
      companyId: userData.companyId,
      companySeries: userData.company_series,
      is_custom_payment_enabled: userData.is_custom_payment_enabled,
    };
  }

  // Function to return params for getUsers and exportToCSV
  getParam(pageSize, currentPage) {
    const params = {
      pageNo: currentPage,
      pageSize: pageSize,
      company: this.selectedCompany,
      environment: this.selectedEnvironment,
      reportingDB: this.selectedReportingDS.slice(),
      any: this.searchKeyword,
    };
    return params;
  }

  @action getUsers(currentPage = this.currentPage) {
    rootStore.sharedStore.showLoader();
    const params = this.getParam(this.pageSize, currentPage);
    // debugger;
    UserService.getUsers(params)
      .then((res) => {
        rootStore.sharedStore.hideLoader();
        let userCount = res.data ? res.data.length : 0;
        this.userModel = [];
        for (let count = 0; count < userCount; count++) {
          let userData = new CustomerModel();

          userData.setUserData(this.getUserData(res.data[count]));
          this.userModel = this.userModel.concat(userData);
        }
        this.currentPage = res.pageInfo.currentPage || 0;
        this.totalPages = res.pageInfo.totalPages || 0;
        this.totalUsers = res.pageInfo.totalUsers || 0;
        this.isReset = false;
      })
      .catch((err) => {
        rootStore.sharedStore.hideLoader();
        console.log(err);
        this.userModel = [];
      });
  }

  @computed get customerList() {
    let customers = [];
    for (let count = 0; count < this.userModel.length; count++) {
      customers.push(Utility.getModelValues(this.userModel[count]));
    }
    return customers;
  }

  @action addUser(params) {
    return UserService.addUser(params);
  }

  @action editUser(params) {
    return UserService.editUser(params);
  }

  @action deleteUser(params) {
    return UserService.deleteUser(params);
  }

  @action getEditUserInfo(params) {
    return UserService.getEditUserData(params);
  }

  @action getCompanyUserInfo(params) {
    return UserService.getCompanyUser(params);
  }

  @action sendInvite(params) {
    return UserService.sendInvite(params);
  }

  @action setCustomPaymentToogle(params) {
    return UserService.paymentToggle(params);
  }

  @action exportDataToCSV() {
    rootStore.sharedStore.showLoader();
    const params = this.getParam(this.totalPages * this.pageSize, 0);
    UserService.getUsers(params)
      .then((res) => {
        rootStore.sharedStore.hideLoader();
        Utility.exportToCsv("customer.csv", res.data);
      })
      .catch((err) => {
        rootStore.sharedStore.hideLoader();
        console.log(err);
        this.userModel = [];
      });
  }

  @action reset() {
    this.isReset = true;
    this.userModel = initialState.userModel;
    this.companyList = initialState.companyList;
    this.companiesList = initialState.companiesList;
    this.environmentList = initialState.environmentList;
    this.licenseSeriesList = initialState.licenseSeriesList;
    this.reportingDSList = initialState.reportingDSList;
    this.totalPages = initialState.totalPages;
    this.currentPage = initialState.currentPage;
    this.totalUsers = initialState.totalUsers;
    this.pageSize = initialState.pageSize;
    this.dataToExport = initialState.dataToExport;
    this.selectedCompany = initialState.selectedCompany;
    this.selectedEnvironment = initialState.selectedEnvironment;
    this.searchKeyword = initialState.searchKeyword;
    this.selectedReportingDS = initialState.selectedReportingDS;
  }

  getList(listName, list) {
    let displayList = list.map((data, i) => {
      if (listName === "licenseSeries") {
        return {
          value: i,
          displayValue: data,
        };
      } else {
        return {
          value: data,
          displayValue: data,
        };
      }
    });
    switch (listName) {
      case "company":
        displayList.unshift(selectCompany);
        break;
      case "environment":
        displayList.unshift(selectEnv);
        break;

      default:
    }
    return displayList;
  }

  getDSList(list) {
    return list.map((data) => {
      return {
        label: data,
        value: data,
      };
    });
  }

  @action getDropdownLists() {
    rootStore.sharedStore.showLoader();
    UserService.dropdownLists()
      .then((res) => {
        rootStore.sharedStore.hideLoader();
        this.companyList = this.getList("company", res.companies);
        this.environmentList = this.getList("environment", res.environments);
        this.licenseSeriesList = this.getList(
          "licenseSeries",
          res.licenseSeries
        );
        this.reportingDSList = this.getDSList(res.databases);
      })
      .catch((err) => {
        console.log(err);
        rootStore.sharedStore.hideLoader();
      });
  }

  @action getDropdownCompanies() {
    rootStore.sharedStore.showLoader();
    UserService.dropdownLists()
      .then((res) => {
        rootStore.sharedStore.hideLoader();
        this.companiesList = this.getDSList(res.companies);
      })
      .catch((err) => {
        console.log(err);
        rootStore.sharedStore.hideLoader();
      });
  }
}

export default UserStore;

import React, { Component } from "react";
import { Modal, Form, Col } from "react-bootstrap";
import AppModal from "../../../UI/AppModal/AppModal";
import Button from "../../../UI/Button/Button";
import { inject, observer } from "mobx-react";

@inject("sharedStore")
@observer
class ViewCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allChecked: false,
      checked: new Map(),
      errorMsg: "",
      disableBtn: "disableBtn",
    };
  }

  handleChange = (e) => {
    let { companyHotFixList } = this.props.sharedStore;
    let { checked, allChecked } = this.state;
    companyHotFixList &&
      companyHotFixList.map((val) => {
        if (checked.has(val.company) && allChecked) {
          checked.set(val.company, false);
        } else if (checked.has(val.company) && allChecked === false) {
          checked.set(val.company, true);
        } else {
          checked.set(val.company, true);
        }
      });
    this.setState({
      checked,
      allChecked: !allChecked,
    });
    setTimeout(() => {
      this.enableDisablecBtn();
    }, 200);
  };

  handleSelected = (e, company) => {
    let { checked, allChecked } = this.state;
    if (checked.has(company)) {
      checked.set(company, !checked.get(company));
    } else {
      checked.set(company, true);
    }
    this.setState({
      checked,
      allChecked: false,
    });
    this.enableDisablecBtn();
  };
  enableDisablecBtn = () => {
    let checkLength = "";
    var customerCheckbox = document.querySelectorAll(".cus_name_checkbox");
    for (var i = 0; i < customerCheckbox.length; i++) {
      if (customerCheckbox[i].checked == true) {
        checkLength++;
      }
    }
    if (checkLength >= 1) {
      this.setState({
        disableBtn: "",
      });
    } else {
      this.setState({
        disableBtn: "disableBtn",
      });
    }
  };

  unlinkCompany = () => {
    const { selectedHotFixId } = this.props;
    const { checked } = this.state;
    if (checked.size > 0) {
      let companyArr = [];
      let flag = 1;
      if (!this.state.allChecked) {
        flag = 0;
        companyArr = [...this.state.checked]
          .map(([name, value]) => ({ name, value }))
          .filter((item) => item.value)
          .map(({ name }) => name);
        console.log("keys", companyArr);
      }
      this.props.sharedStore
        .removeAssignedHotFix(selectedHotFixId, companyArr, flag)
        .then((data) => {
          this.props.sharedStore.hideLoader();
          if (data.status_code === 200) {
            this.props.sharedStore.getHotFixList();
            this.props.sharedStore.fetchCompanyHotFixList(selectedHotFixId);
            this.props.setToastData("success", data.message);
          }
          checked.clear();
          this.setState({
            checked,
            allChecked: false,
            disableBtn: "disableBtn",
          });
          this.props.modalHandler();
        })
        .catch((err) => {
          this.props.sharedStore.hideLoader();
          this.props.setToastData("error", "Internal Server Error");
        });
    } else {
      this.setState({
        errorMsg: "Please select atleast one company",
      });
    }
  };

  closeModal = () => {
    const { checked } = this.state;
    checked.clear();
    this.setState({
      checked,
      allChecked: false,
      disableBtn: "disableBtn",
    });
    this.props.modalHandler();
  };

  render() {
    const { selectedHotFix } = this.props;
    const { checked, allChecked, errorMsg } = this.state;
    return (
      <AppModal
        showModal={this.props.modalVisibility}
        modalHandler={this.closeModal}
        modalCeneterd={true}
        dialogCustomClass="form-modal viewcustomer custom_build"
        modalSize="md"
        modalBackdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">Custom Builds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="list_build_name">
            <Form.Row>
              <Col xs={12} sm={12} md={12}>
                <span className="custombuild_info">
                  Customers with Access to {selectedHotFix}{" "}
                </span>
                <label for="checkAll">
                  <input
                    type="checkbox"
                    value="checkAll"
                    checked={allChecked}
                    onChange={this.handleChange}
                    id="checkAll"
                  />
                  Select All
                </label>
                {this.props.sharedStore.companyHotFixList &&
                  this.props.sharedStore.companyHotFixList.map((val, i) => {
                    return (
                      <label key={i} for={val.company} className>
                        <input
                          type="checkbox"
                          checked={checked.get(val.company)}
                          onChange={(e) => this.handleSelected(e, val.company)}
                          className="cus_name_checkbox"
                          id={val.company}
                        />
                        {val.company_name}
                      </label>
                    );
                  })}
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <div>{errorMsg}</div> */}
          <Button
            btnVariant="primary"
            btnClass={"custom_btn_enable " + this.state.disableBtn}
            clickHandler={this.unlinkCompany}
          >
            Remove Access
          </Button>
          <Button
            btnVariant="secondary"
            clickHandler={(e) => this.closeModal(e)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </AppModal>
    );
  }
}

export default ViewCustomers;


/*
 * @Author: Pulkit
 * @Date: 2019-03-19 10:25:10
 * @Last Modified by: Pulkit
 */

const errorMessages = {
    nameRequired: ' Name is required.',
    nameValidation: 'Name must contain two characters.',
    firstNameRequired: 'First Name is required.',
    lastNameRequired: 'Last Name is required.',
    emailRequired: 'Email is required.',
    passwordRequired: 'Password is required.',
    confirmPasswordRequired: 'Confirm password is required.',
    oldPasswordRequired: 'Old Password is required.',
    passwordMismatch: 'Password and Confirm password do not match.',
    otpRequired: 'Otp is required',
    otpValidation: 'Please enter correct otp',
    phoneNoRequired: 'Phone number is required.',
    userTypeRequired:'User Type is required',
    emailValidation: 'Please enter correct email.',
    phoneNoValidation: 'Please enter correct phone number.',
    passwordValidation: 'Please enter correct password.',
    firstNameValidation: 'Please enter valid first name',
    lastNameValidation: 'Please enter valid last name',
    companyValidation: 'Please enter company name.',
    environmentValidation: 'Please select environment from the list.',
    countryValidation: 'Please select country from the list.',
    stateValidation: 'Please select state from the list.',
    cityValidation: 'Please select city from the list.',
    reportingDBRequired: 'Please select reporting data source from the list.',
    networkError: 'Network Error.'
}

export default errorMessages;
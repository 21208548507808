import React, { Component, Suspense } from "react";
import { Container, Col, Row, Modal } from "react-bootstrap";
import Icon from "react-icons-kit";
import { inject, observer } from "mobx-react";


@inject("sharedStore")
@observer
class BlockingIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
        setActiveTab:'knowissue',
        showModal:false
    };

}

 renderActiveTab = (tabName) => {
  console.log("tabname+++"+tabName)
    this.setState({
      setActiveTab: tabName
    });
    
  };
  
  triggerModal = (e) => {
    console.log("Modal Triggered")
    this.setState({
      showModal:!this.state.showModal
    });
    
  }


  render() {
    return (
      <section className="user-section">
        <Container>
          <div className="custom_build_wrapper">
            <div className="headrer_link">
              <a href="/dashboard" className="goback">
                Back
              </a>
            </div>
            <div className="block_header">Known Blocking Issues</div>
            <div className="blocking_issue_container">
              <div className="upgrade-card custom_upreade_wrapper">
                <div className="card_top">
                  <div className={this.state.setActiveTab === "knowissue" ? "new_list active" : "new_list"} onClick={() => this.renderActiveTab('knowissue')}>Known Blocking Issues</div>
                  <div className={this.state.setActiveTab === "archive" ? "new_list active" : "new_list"} onClick={() => this.renderActiveTab('archive')}>Archive</div>
                  <div className="legend_tab">
                    <div className="opent_leg_tab"><span className="tab_open_box"></span>Open</div>
                    <div className="opent_leg_tab"><span className="tab_closed_box"></span>Closed</div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="breaking_changes">
                    {this.state.setActiveTab === 'knowissue' ? (
                      <div>
                      <table className="kn_issue_table">
                        <thead>
                          <th>Defect</th>
                          <th>Version(s) Affected</th>
                          <th>Targeted Resolution Date</th>
                          <th>Resolution Custom Build</th>
                        </thead>
                        <tbody>
                          <tr className="active_row"><td className="issue_des">Name of the issue or a brief description</td><td>V3.21 v3.2.2</td><td>12/12/2020</td><td>v3.2.3</td>
                          </tr>
                          <tr><td>Name of the issue or a brief description</td><td>V3.21 v3.2.2</td><td>12/12/2020</td><td>v3.2.3</td></tr>
                          <tr><td>Name of the issue or a brief description</td><td>V3.21 v3.2.2</td><td>12/12/2020</td><td>v3.2.3</td></tr>
                          <tr><td>Name of the issue or a brief description</td><td>V3.21 v3.2.2</td><td>12/12/2020</td><td>v3.2.3</td></tr>
                          <tr><td>Name of the issue or a brief description</td><td>V3.21 v3.2.2</td><td>12/12/2020</td><td>v3.2.3</td></tr>
                          <tr><td>Name of the issue or a brief description</td><td>V3.21 v3.2.2</td><td>12/12/2020</td><td>v3.2.3</td></tr>
                          </tbody>
                      </table>
                    </div>
                    ): null}
                    {this.state.setActiveTab === 'archive' ? (
                      <div>
                        <table className="kn_issue_table">
                        <thead>
                          <th>Defect</th>
                          <th>Version(s) Affected</th>
                          <th>Date Resolved</th>
                          <th>Resolution Custom Build</th>
                          
                        </thead>
                        <tbody>
                          <tr>
                            <td className="issue_des">
                              Name of the issue or a brief description
                            </td>
                            <td>V3.21 v3.2.2</td>
                            <td>12/12/2020</td>
                            <td>v3.2.3</td>
                            
                          </tr>
                          <tr>
                            <td>Name of the issue or a brief description</td>
                            <td>V3.21 v3.2.2</td>
                            <td>12/12/2020</td>
                            <td>v3.2.3</td>
                            
                          </tr>
                          <tr>
                            <td>Name of the issue or a brief description</td>
                            <td>V3.21 v3.2.2</td>
                            <td>12/12/2020</td>
                            <td>v3.2.3</td>
                            
                          </tr>
                          <tr>
                            <td>Name of the issue or a brief description</td>
                            <td>V3.21 v3.2.2</td>
                            <td>12/12/2020</td>
                            <td>v3.2.3</td>
                            
                          </tr>
                          <tr>
                            <td>Name of the issue or a brief description</td>
                            <td>V3.21 v3.2.2</td>
                            <td>12/12/2020</td>
                            <td>v3.2.3</td>
                            
                          </tr>
                          <tr>
                            <td>Name of the issue or a brief description</td>
                            <td>V3.21 v3.2.2</td>
                            <td>12/12/2020</td>
                            <td>v3.2.3</td>
                            
                          </tr>
                        </tbody>
                      </table>
                      </div>
                    ): null}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div>
        <Modal
          size="md"
          show={this.state.showModal}
          aria-labelledby="example-modal-sizes-title-lg"
          onHide={this.triggerModal}
          className="blockingissue-modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">CONFIRMATION</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="md_txt_body">Are you sure you want to delete this defect?</div>
          </Modal.Body>
          <div className="bottm_action text-center">
              <button type="button" class="app-btn account_saveBtn btn btn-primary">YES, DELETE</button>
              <button type="button" class="btn btn_cancle_defect" onClick={this.triggerModal}>CANCEL</button>
          </div>
        </Modal>
        </div>

      </section>
    );
  }
}

export default BlockingIssue;

import URLS from "../common/api";
import http from "../interceptor";

export default {
  getUsers: (params) => http.post(URLS.userList, params),
  addUser: (params) => http.post(URLS.addUser, params),
  editUser: (params) => http.post(URLS.editUser, params),
  deleteUser: (params) => http.post(URLS.deleteUser, params),
  dropdownLists: () => http.get(URLS.dropdownLists),
  countryData: () => http.get(URLS.countryData),
  exportData: () => http.get(URLS.exportData),
  getEditUserData: (params) => http.post(URLS.editUserDetails, params),
  getCompanyUser: (params) => http.post(URLS.getCompanyUser, params),
  getInvoiceData: (params) => http.post(URLS.fetchInvoiceData, params),
  viewDocument: (params) => http.get(`${URLS.fetchInvoiceData}${params.key}`),
  addInvoice: (data, config) => http.post(URLS.addInvoice, data, config),
  inviteMember: (params) => http.post(URLS.inviteMember, params),
  checkMember: (params) => http.post(URLS.checkMember, params),
  getCompanyList: () => http.get(URLS.getCompanyList),
  removeDocument: (params) => http.post(URLS.removeDocument, params),
  fetchLicenseInfo: (params) => http.post(URLS.fetchLicenseInfo, params),
  fetchSubClientInfo: (params) => http.post(URLS.fetchSubClientInfo, params),
  updateAmount: (params) => http.post(URLS.updateAmount, params),
  updateCompanyLicense: (params) =>
    http.post(URLS.updateCompanyLicense, params),
  checkExistingUser: (params) => http.post(URLS.checkExistingUser, params),
  sendInvite: (params) => http.post(URLS.sendInvite, params),
  paymentToggle: (params) => http.post(URLS.paymentToggle, params),
  validateFile: (data, config) => http.post(URLS.validateFile, data, config),
  saveCreditMemo: (data, config) => http.post(URLS.creditMemo, data, config),
  fetchCreditMemo: (data) => http.post(URLS.fetchCreditMemo, data),
  getSubLicenseAllowance: (params) =>
    http.get(
      `${URLS.subLicenseAllowance}?company_id=${params.company_id}`,
      params
    ),
  putSubLicenseAllowance: (data) => http.put(URLS.subLicenseAllowance, data),
  updateSubLicenseMode: (data) => http.post(URLS.updateSubLicenseMode, data),
  generateSubLicense: (data) => http.post(URLS.createLicense, data),
  getHotFixList: () => http.get(URLS.fetchHotFixes),
  fetchCompanyHotFixList: (data) => http.post(URLS.fetchHotFixes, data),
  postHotFix: (data) => http.post(URLS.postHotFix, data),
  deleteHotFix: (data) => http.post(URLS.deleteHotFix, data),
  assignHotFixes: (data) => http.post(URLS.assignHotFixes, data),
  removePublicHotFix: (data) => http.post(URLS.removePublicHotFix, data),
  removeAssignedHotFix: (data) => http.post(URLS.removeAssignedHotFix, data),
  fetchResources: (params) =>
    http.get(`${URLS.fetchResources}/${params.version}`),
  fetchVersions: () => http.get(URLS.fetchResources),
};

import React from "react";
import Button from "../../UI/Button/Button";

const navigationItem = props => (
  // <Link
  //   to={props.link}
  //   exact={props.exact}
  //   className={props.navItemClass}
  // >
  //   {props.children}
  // </Link>
  <Button
    btnClass={props.navItemClass}
    btnType={props.navtype}
    clickHandler={props.clickHandler}
    btnVariant={props.navVariant}
  >
    {props.children}
  </Button>
);

export default navigationItem;

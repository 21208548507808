import React, { Component, Suspense } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Utility from "../../../common/utility";
import InputField from "../../UI/InputField/InputField";
import SelectField from "../../UI/SelectField/SelectField";
import DataTable from "../../UI/DataTable/DataTable";
import Toast from "../../UI/Toast/Toast";
import Button from "../../UI/Button/Button";
import CustomPagination from "../../UI/Pagination/Pagination";
import Global from "../../../common/global";
import errorMessage from "../../../common/errorMessages";
import "./Dashboard.scss";
import NavigationItem from "../../Navigation/NavigationItem/NavigationItem";
import InviteComponent from "../Members/Invite/InviteComponent";
import AddUser from "../../../containers/UserManagement/AddUser";
const Dialog = React.lazy(() => import("../../UI/Dialog/Dialog"));

let isDataDeletable = false;

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      expirationDate: "",
      registrationDate: "",
      environment: "",
      reportingDB: [],
      isDeleteUserDialogOpen: false,
      globalFilter: "",
      responseType: "",
      responseTitle: "",
      responseMessage: "",
      selectedUserId: null, // for deleting the user
      isAddUserModalOpen: false,
    };

    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onEnvironmentChange = this.onEnvironmentChange.bind(this);
    this.onDBChange = this.onDBChange.bind(this);
    this.userDataActionTemplate = this.userDataActionTemplate.bind(this);
    this.exportDataToCSV = this.exportDataToCSV.bind(this);
    this.showToast = this.showToast.bind(this);
    this.setToastData = this.setToastData.bind(this);
    this.showDeleteUserDialog = this.showDeleteUserDialog.bind(this);
    this.hideDeleteUserDialog = this.hideDeleteUserDialog.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.sortByRegistrationDate = this.sortByRegistrationDate.bind(this);
    this.sortByExpirationDate = this.sortByExpirationDate.bind(this);
    this.navigateToAccount = this.navigateToAccount.bind(this);
  }

  componentDidMount() {
    this.setState({
      company: this.props.userStore.selectedCompany,
      environment: this.props.userStore.selectedEnvironment,
      reportingDB: this.props.userStore.selectedReportingDS,
      globalFilter: this.props.userStore.searchKeyword,
    });
    this.props.sharedStore.getCompanyList();

    this.props.userStore.getUsers();
    this.props.userStore.getDropdownLists();
    this.props.sharedStore.getCountryData();
  }

  onCompanyChange(event) {
    this.props.userStore.selectedCompany = event.target.value;
    this.setState({ company: event.target.value });
  }

  onEnvironmentChange(event) {
    this.props.userStore.selectedEnvironment = event.target.value;
    this.setState({ environment: event.target.value });
  }

  onDBChange(event) {
    this.props.userStore.selectedReportingDS = event.value;
    this.setState({ reportingDB: event.value });
  }

  // Sorting by registration date
  sortByRegistrationDate(event) {
    if (event.order === -1) {
      return this.props.userStore.customerList.sort(
        (a, b) => b.registrationDate - a.registrationDate
      );
    }
    return this.props.userStore.customerList.sort(
      (a, b) => a.registrationDate - b.registrationDate
    );
  }

  // Sorting by expiration date
  sortByExpirationDate(event) {
    if (event.order === -1) {
      return this.props.userStore.customerList.sort(
        (a, b) => b.expirationDate - a.expirationDate
      );
    }
    return this.props.userStore.customerList.sort(
      (a, b) => a.expirationDate - b.expirationDate
    );
  }

  // Function to navigate to account page
  navigateToAccount(event) {
    let companyMemberId = event.value.customerId;
    let companyId = event.value.companyId;
    this.props.sharedStore.setTokenId(event.value.token_setting_id);
    this.props.sharedStore.set_payment_enabled(
      event.value.is_custom_payment_enabled
    );
    this.props.sharedStore.setCompanyId(companyId);
    this.props.sharedStore.setCompanyName(event.value.company);
    this.props.sharedStore.setCompanyMemberId(companyMemberId);
    this.props.sharedStore.setCompanySeriesType(event.value.companySeries);
    this.props.history.push("/account", { customerId: companyMemberId });
  }

  // Function to show delete customer dialog
  showDeleteUserDialog(event, rowData, column) {
    event.stopPropagation();
    this.setState({
      isDeleteUserDialogOpen: true,
      selectedUserId: rowData.customerId,
    });
  }

  // Function to hide delete customer dialog
  hideDeleteUserDialog(isUserDeleted = false) {
    if (isUserDeleted === true) {
      this.props.userStore.getUsers();
    }
    this.setState({
      isDeleteUserDialogOpen: false,
    });
  }

  // Function to delete customer data
  onDelete() {
    const loginStore = Utility.getData("loginStore");
    const loggedUser = loginStore ? loginStore.userEmail : "";

    let params = {
      id: this.state.selectedUserId,
      by: loggedUser,
    };

    this.props.sharedStore.showLoader();
    this.props.userStore
      .deleteUser(params)
      .then((res) => {
        this.props.sharedStore.hideLoader();
        if (res.responseCode === 200) {
          this.hideDeleteUserDialog(true);
          this.setToastData("success", res.message);
        } else {
          this.setToastData("error", res.message);
        }
      })
      .catch((err) => {
        this.props.sharedStore.hideLoader();
        console.log("err", err);
        this.setToastData("error", errorMessage.networkError);
      });
  }

  handleSearchChange(e) {
    this.setState({ globalFilter: e.target.value });
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      this.props.userStore.searchKeyword = e.target.value;
    }
  }

  setToastData(toastType, toastMessage) {
    this.setState(
      {
        responseType: toastType,
        responseTitle: toastType,
        responseMessage: toastMessage,
      },
      () => this.showToast()
    );
  }

  showToast() {
    const { responseType, responseTitle, responseMessage } = this.state;
    this.growl.show({
      severity: responseType,
      summary: responseTitle,
      detail: responseMessage,
      life: 2200,
    });
  }

  // Function to export customer data in CSV format
  exportDataToCSV() {
    this.props.userStore.exportDataToCSV();
  }

  getEditableColData() {}

  userDataActionTemplate(rowData, column) {
    let currentUserType = this.props.loginStore.userType;
    let userType = Global.userType;

    switch (currentUserType) {
      case userType.admin:
      case userType.staff:
        isDataDeletable = false;
        return <div></div>;
      case userType.superUser:
        isDataDeletable = true;
        return (
          <Button
            type="button"
            btnClass="cell-btn mlr-0"
            btnVariant="danger"
            clickHandler={(e) => this.showDeleteUserDialog(e, rowData, column)}
          >
            <i className="pi pi-trash" />
          </Button>
        );
      default:
    }
  }

  // Registration Date Column Template
  registrationDateTemplate(rowData, column) {
    return (
      <div>
        {rowData.registrationDate == ""
          ? ""
          : Utility.convertUnixTimestampToDate(rowData.registrationDate)}
      </div>
    );
  }

  // Expiration Date Column Template
  expirationDateTemplate(rowData, column) {
    return (
      <div>
        {rowData.expirationDate == ""
          ? ""
          : Utility.convertUnixTimestampToDate(rowData.expirationDate)}
      </div>
    );
  }
  toggleAddUserModal = (isUserAdded = false) => {
    this.setState({
      isAddUserModalOpen: !this.state.isAddUserModalOpen,
    });
  };

  render() {
    let {
      companyList,
      environmentList,
      reportingDSList,
      customerList,
      currentPage,
      totalPages,
    } = this.props.userStore;

    return (
      <section className="user-section">
        <Container>
          <div>
            <div className="dashboardHeader">Customer Management</div>
            <div className="dashboardmenu">
              {/* <div className="text-right mb-4 dashboard-item">
                <a className="app-btn nw_cusbtn" href="/blockingissue">
                  Blocking Issues
                </a>
              </div> */}
              <div className="text-right mb-4 dashboard-item">
                <a
                  className="app-btn nw_cusbtn custom_build"
                  href="/custombuild"
                >
                  Custom Builds
                </a>
              </div>
              <div className="text-right mb-4 dashboard-item">
                <Button
                  btnClass="app-btn nw_cusbtn"
                  btnType="button"
                  btnVariant="primary"
                  clickHandler={this.exportDataToCSV}
                >
                  Export to CSV
                </Button>
              </div>
              <div className="text-right mb-4 dashboard-item">
                <Button
                  btnClass="app-btn"
                  btnType="button"
                  btnVariant="primary"
                  clickHandler={this.toggleAddUserModal}
                >
                  Add Internal User
                </Button>
              </div>

              {this.state.isAddUserModalOpen ? (
                <AddUser
                  modalHandler={this.toggleAddUserModal}
                  modalVisibility={this.state.isAddUserModalOpen}
                  setToastData={this.setToastData}
                />
              ) : null}
            </div>

            {/* User Table Filters */}
            <Row className="filter-row clearfix">
              <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                <span className="filter-label">Filter By:</span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={2} xl={2}>
                <SelectField
                  label=""
                  fieldClass="filter-control"
                  name="company"
                  onChange={this.onCompanyChange}
                  selectOptions={companyList}
                  value={this.state.company}
                  error=""
                  errorMessage=""
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={2} xl={2}>
                <SelectField
                  label=""
                  fieldClass="filter-control"
                  name="environment"
                  onChange={this.onEnvironmentChange}
                  selectOptions={environmentList}
                  value={this.state.environment}
                  error=""
                  errorMessage=""
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={3} xl={2}>
                <MultiSelect
                  style={{ width: "100%" }}
                  filter={true}
                  value={this.state.reportingDB}
                  options={reportingDSList.slice()}
                  onChange={this.onDBChange}
                  placeholder="Reporting DB"
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={3} xl={4}>
                <InputField
                  type="text"
                  class="filter-control"
                  name="search"
                  placeholder="Search"
                  value={this.state.globalFilter}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handleSearchChange}
                />
              </Col>
            </Row>
            {/* User Table Filters */}
          </div>
          {customerList.length !== 0 ? (
            <React.Fragment>
              <DataTable
                tableRef={(el) => (this.dt = el)}
                tableData={customerList}
                isEditable={true}
                exportFileName="Customer-Data"
                getEditableCol={this.getEditableColData}
                selectionMode="single"
                onSelectionChange={this.navigateToAccount}
              >
                {/* <Column field="name" header="Name" sortable={true} /> */}
                <Column field="company" header="Company" sortable={true} />
                <Column
                  field="expirationDate"
                  body={this.expirationDateTemplate}
                  header="License Expiration Date"
                  sortable={true}
                  sortFunction={this.sortByExpirationDate}
                />
                <Column
                  field="registrationDate"
                  body={this.registrationDateTemplate}
                  header="Registration Date"
                  sortable={true}
                  sortFunction={this.sortByRegistrationDate}
                />
                <Column
                  field="environment"
                  header="Environment"
                  sortable={true}
                />
                <Column
                  field="reportingDB"
                  header="Reporting Data Source"
                  sortable={true}
                />
                <Column
                  header={isDataDeletable ? "Delete" : ""}
                  body={this.userDataActionTemplate}
                  className={isDataDeletable ? "show" : "d-none"}
                />
              </DataTable>

              {/* Pagination Component */}
              <CustomPagination
                wrapperClass="mt-4 justify-content-center flex-wrap"
                totalPages={totalPages}
                currentPage={currentPage + 1}
                onPageChange={(pageNo) => {
                  this.props.userStore.currentPage = pageNo - 1;
                }}
              />
            </React.Fragment>
          ) : (
            <p className="text-center mt-4">No Record found</p>
          )}

          {/* Delete Confirmation Dialog */}
          {this.state.isDeleteUserDialogOpen ? (
            <Suspense fallback="">
              <Dialog
                modalVisibility={this.state.isDeleteUserDialogOpen}
                modalHandler={this.hideDeleteUserDialog}
                headerTitle="Are you sure you want to delete this user ?"
                modalBody=""
                userId={this.state.userId}
                btnCancelLabel="Cancel"
                btnSubmitLabel="Confirm"
                submitHandler={this.onDelete}
              />
            </Suspense>
          ) : null}

          {/* Error, Success Message Taost */}
          <Toast toastRef={(el) => (this.growl = el)} />
        </Container>
      </section>
    );
  }
}

export default DashboardComponent;

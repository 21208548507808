import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import TeamsComponent from "./Teams/TeamsComponent"
import Utility from "../../../common/utility";
import CustomerModel from "../../../models/CustomerModel";
import "./Members.scss"
import resendInviteIcon from '../../../assets/images/resend-invite-icon.png';
import ResendInvite from './ResendInvite/ResendInvite'
import InviteComponent from "./Invite/InviteComponent"
import { inject, observer } from "mobx-react";
import Button from "../../UI/Button/Button";
import spinnerImg from '../../../assets/images/loader.gif';



@observer class MembersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: new CustomerModel(),
      companyMatch: [],
      result: [],
      visibleModal: '',
      isFormSubmitted: '',
      successModal: '',
      inserted: '',
      errMsg: '',
      showPopup: '',
      email: '',
      index: '',
      isLastElement: false,
      isInviteMemberModalOpen: false,
    };
  }

  componentDidMount() {

    if (!this.props.userInfo) {
      this.props.navigateToDashboard();
    }
    this.props.userStore.getUsers();
    let params = {
      // token_setting_id: this.props.sharedStore.token_setting_id,
      "id": this.props.sharedStore.companyId
    };
    this.getMemberData(params);
  }

  getMemberData(params) {
    this.props.userStore.getCompanyUserInfo(params).then(res => {
      this.props.sharedStore.hideLoader();
      if (res.responseCode === 200) {
        this.props.sharedStore.setMemberList(res.data.result);
        this.setState({
          result: res.data.result
        })
      }
    }).catch(err => {
      this.props.sharedStore.hideLoader();
      console.log("err", err);
    })
  }

  resendInvite(email, i) {
    if (this.props.sharedStore.memberList.length == i + 1) {
      this.setState({ isLastElement: true });
    } else {
      this.setState({ isLastElement: false });
      let clickedImage = document.getElementById("image" + i);
      let distanceFromTop = clickedImage.getBoundingClientRect().top
      let difference = window.innerHeight - distanceFromTop;
      if (this.state.showPopup == "" && difference < 180 && difference > 120) {
        window.scrollTo(0, window.pageYOffset + 80);
      } else if (this.state.showPopup == "" && difference < 121 && difference > 60) {
        window.scrollTo(0, window.pageYOffset + 110);
      } else if (this.state.showPopup == "" && difference < 61) {
        window.scrollTo(0, window.pageYOffset + 150);
      }
    }
    this.setState({
      showPopup: 'popup' + i == this.state.showPopup ? '' : 'popup' + i,
      email: email,
      index: i
    })
  }

  closeInviteDiv = () => {
    this.setState({
      showPopup: '',
      index: '',
      email: ''
    })
  }

  renderText(token_type,expired){
    if(expired){
      if(token_type === 'registration'){
        return(
          <span className="link_exp_text">Invitation Link Expired</span>
        )
      }
      else if(token_type === 'verification'){
        return (
          <span className="link_exp_text">Account Verification Link Expired</span>
        )
      }
    }
    else{
      if(token_type === 'verification'){
        return(
          <span className="link_exp_text">Account Verification Pending</span>
        )
      }
    }

  }

  renderMemberList() {
    let result = this.props.sharedStore.memberList
    return result && result.map((match, i) => {
      let memberLocation = ''
      let memberPhone = ''
      return (
        <tr key={i} className='membersData'>
          <td>{match.name}</td>
          <td>{match.status == 'pending' && match.created_at != match.updated_at ? <p className="invite-text">Invitation resent on {Utility.convertUnixTimestampToDate(match.updated_at)}</p> : memberLocation}</td>
          <td>{match.status == 'pending' ? 
          <span className="memberStatus">Pending Invite 
          <img className="resend-invite" id={"image" + i} 
          onClick={() => this.resendInvite(match.email, i)} 
          src={resendInviteIcon} /><br />
            {this.renderText(match.token_type,match.link_expired)}
          </span> : memberPhone} {this.state.showPopup === 'popup' + i && this.state.index == i ?
            <ResendInvite token_type={match.token_type} email={this.state.email} getMemberData={this.getMemberData} closeInvite={this.closeInviteDiv} setToastData={this.props.setToastData} /> : null}</td>
          <td>{match.email}</td>
          <td>{match.role}</td>
          <td>{Utility.convertUnixTimestampToDate(match.created_at)}</td>
          <td>{match.status == 'pending' ? '' : match.status}</td>
        </tr>
      )
    })
  }

  closeModal = () => {
    this.setState({
      visibleModal: ''
    })
  }

  closeSuccessModal = () => {
    this.setState({
      successModal: ''
    })
  }

  handleActiveTab(name) {
    this.setState({
      selectedTab: name
    });
  }

  handleFormSubmission = (value, inserted, errMsg) => {
    this.setState({
      isFormSubmitted: value,
      successModal: value,
      inserted: inserted,
      errMsg: errMsg
    });
  }
  toggleInviteMemberModal = () => {
    this.setState({
      isInviteMemberModalOpen: !this.state.isInviteMemberModalOpen
    });
  }
 
 
  render() {
    let { visibleModal, isLastElement } = this.state;
    let { setToastData } = this.props;

    return (
      
      <div className="inner-tab-wrapper edge-sol d-flex flex-column">

        {/* <div className='membersBtnContainer'>
          <button className='btnOrange' onClick={() => this.setState({ visibleModal: true })}>Invite Member</button>
        </div>
        {
          visibleModal && <InviteComponent getMemberData = {this.getMemberData} setToastData={setToastData} isFormSubmitted={this.handleFormSubmission} modalVisibility={visibleModal} modalHandler={this.closeModal} modalClass="iz-modal invite-modal" />
        } */}
        <Tabs defaultActiveKey="members" transition={false} id="noanim-tab-example" className='membersTab'>
          <Tab eventKey="members" title="Members">
            <div className={isLastElement ? "table-responsive members-data-table overflow-remove" : "table-responsive members-data-table"}>
            <div className="invite_cumember">
                <Button
                  btnClass="app-btn"
                  btnType="button"
                  btnVariant="primary"
                  clickHandler={this.toggleInviteMemberModal}
                >
                   Invite Member
                  </Button>
              </div>
              
              {this.state.isInviteMemberModalOpen ?
                <InviteComponent
                modalHandler={this.toggleInviteMemberModal}
                modalVisibility={this.state.isInviteMemberModalOpen}
                setToastData={setToastData}
                modalClass="iz-modal invite-modal"

                /> : null}
              <table className="member-table w-100">
                <thead>
                  <tr>
                    <th>Member</th>
                    <th>Location</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Created</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderMemberList()}

                </tbody>
              </table>
            </div>
          </Tab>
          {/* <Tab eventKey="teams" title="Teams">
            <TeamsComponent />
          </Tab> */}
        </Tabs>
      </div>
    );
  }
}

export default MembersComponent;
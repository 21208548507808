import React, { Component, Suspense } from "react";
import { Modal, Form, Col } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import Utility from "../../../../common/utility";
import AppModal from "../../../UI/AppModal/AppModal";
import Button from "../../../UI/Button/Button";
import AlertBox from "../../../UI/Alert/Alert";
import errorMessage from "../../../../common/errorMessages";
import { toJS } from 'mobx';
import './Invite.scss';

@inject('sharedStore', 'userStore')
@observer class InviteComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                // firstName: {
                //     value: "",
                //     error: "",
                //     errMsg: ""
                // },
                // lastName: {
                //     value: "",
                //     error: "",
                //     errMsg: ""
                // },
                email: {
                    value: "",
                    error: "",
                    errMsg: ""
                },
                role: {
                    value: "",
                    error: "",
                    errMsg: ""
                },
                company: {
                    value: "",
                    error: "",
                    errMsg: ""
                }
            },
            isErrorAlertOpen: false
        };

    }

    onInputChange = e => {
        let form = { ...this.state.form };
        form[e.target.name].value = e.target.value;
        form[e.target.name].error = false;
        form[e.target.name].errMsg = "";
        this.setState({ form });
    }


    handleValidation() {
        const form = { ...this.state.form };
        let formIsValid = true;
        // if (!form.firstName.value) {
        //     formIsValid = false;
        //     form.firstName.error = true;
        //     form.firstName.errMsg = "Required!";
        // }
        // if (!form.lastName.value) {
        //     formIsValid = false;
        //     form.lastName.error = true;
        //     form.lastName.errMsg = "Required!";
        // }
        if (!form.email.value) {
            formIsValid = false;
            form.email.error = true;
            form.email.errMsg = "Required!";
        }
        if (!form.role.value) {
            formIsValid = false;
            form.role.error = true;
            form.role.errMsg = "Required!";
        }
        // if (!form.company.value) {
        //     formIsValid = false;
        //     form.company.error = true;
        //     form.company.errMsg = "Required!";
        // }
        this.setState({ form });
        return formIsValid;
    }

    validateEmail() {
        const form = { ...this.state.form };
        let formIsValid = true;
        if (!form.email.value) {
            formIsValid = false;
            form.email.error = true;
            form.email.errMsg = "Required!";
        }
        if (form.email.error) {
            formIsValid = false;
        }
        this.setState({ form });
        return formIsValid;
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        // const { firstName, lastName, role, email, company } = this.state.form
        const { role, email, company } = this.state.form
        if (this.handleValidation()) {
            if (this.validateEmail()) {
                let errMsg = ''
                this.props.sharedStore.showLoader();
                let data = {
                    // "firstName": firstName.value,
                    // "lastName": lastName.value,
                    "roleId": role.value,
                    "email": email.value,
                    "companyId": this.props.sharedStore.companyId,
                }
                this.props.sharedStore.inviteMember(data).then(res => {
                    this.props.sharedStore.hideLoader();
                    console.log('resssss',res)
                    if (res.responseCode === 200) {
                        console.log('im in 200')
                        let params = {
                            "id": this.props.sharedStore.companyId
                        };
                        this.props.userStore.getCompanyUserInfo(params).then(res => {
                            this.props.sharedStore.hideLoader();
                            if (res.responseCode === 200) {
                                this.props.sharedStore.setMemberList(res.data.result);
                            }
                        }).catch(err => {
                            this.props.sharedStore.hideLoader();
                            console.log("err", err);
                        })
                        this.props.modalHandler();
                        console.log('sucesssss')
                        this.props.setToastData('success', res.message);
                    } else {
                        this.props.setToastData('error', res.message);
                    }
                }).catch(err => {
                    this.props.sharedStore.hideLoader();
                    this.props.setToastData('error', errorMessage.networkError);
                })

            }
        }
    }



    checkEmail = () => {
        const form = { ...this.state.form };
        let isValidEmail = Utility.isValidEmail(this.state.form.email.value)
        if (isValidEmail) {
            this.props.sharedStore.showLoader();
            let data = {
                "email": this.state.form.email.value
            }

            this.props.sharedStore.checkMember(data).then(res => {
                this.props.sharedStore.hideLoader();
                if (res.error === true) {
                    form.email.error = true;
                    form.email.errMsg = res.message;
                    this.setState({ form });
                }
            }).catch(err => {
                this.props.sharedStore.hideLoader();
            })
        }
        else {
            form.email.error = true;
            form.email.errMsg = "Invalid Email!";
            this.setState({ form });
        }
    }

    render() {
        // const { form: { firstName, lastName, email, role, company }, errorMessage } = this.state;
        const { form: { email, role, company }, errorMessage } = this.state;
        const { sharedStore } = this.props
        return (
            <AppModal
                showModal={this.props.modalVisibility}
                modalHandler={this.props.modalHandler}
                modalCeneterd={true}
                dialogCustomClass="change-password-modal"
                modalSize="md"
                modalBackdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Invite Member</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Row id='inviteModal'>
                            {/* <Col xs={12} sm={12} md={12}>
                                <div className={firstName.error ? "form-group error-wrapper" : "form-group"}>
                                    <label for="fName">First Name *</label>
                                    <input
                                        type="label"
                                        required
                                        maxLength="50"
                                        id="fName"
                                        autoComplete="off"
                                        className="form-control"
                                        name="firstName"
                                        value={firstName.value}
                                        onChange={(e) => this.onInputChange(e)}>
                                    </input>
                                    {
                                        firstName.error ? <span className={"text-danger text-sm inviteError"}> {firstName.errMsg}</span> : ''
                                    }
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <div className={lastName.error ? "form-group error-wrapper" : "form-group"}>
                                    <label for="lName">Last Name *</label>
                                    <input
                                        type="label"
                                        required
                                        maxLength="50"
                                        autoComplete="off"
                                        className="form-control"
                                        name="lastName"
                                        id="lName"
                                        value={lastName.value}
                                        onChange={(e) => this.onInputChange(e)}>
                                    </input>
                                    {
                                        lastName.error ? <span className={"text-danger text-sm inviteError"}> {lastName.errMsg}</span> : ''
                                    }
                                </div>
                            </Col> */}
                            <Col xs={12} sm={12} md={12}>
                                <div className={email.error ? "form-group error-wrapper" : "form-group"}>
                                    <label for="e-mail">Email *</label>
                                    <input
                                        required
                                        type="text"
                                        maxLength="50"
                                        autoComplete="off"
                                        className="form-control"
                                        name="email"
                                        id="e-mail"
                                        value={email.value}
                                        onChange={(e) => this.onInputChange(e)}
                                        onBlur={() => this.checkEmail()}>
                                    </input>
                                    {
                                        email.error ? <span className={"text-danger text-sm inviteError"}> {email.errMsg}</span> : ''
                                    }
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <div className={company.error ? "form-group error-wrapper" : "form-group"}>
                                    <label for="cmpy">Company *</label>
                                    {/* <select
                                        required
                                        className={!company.value ? "form-control selection" : "form-control"}
                                        name="company"
                                        id="cmpy"
                                        value={company.value}
                                        onChange={(e) => this.onInputChange(e)}
                                    >]
                                        <option disable hidden value="" >Select Company</option>
                                        {
                                            sharedStore.companyArr && sharedStore.companyArr.map((company, i) => {
                                                console.log('')
                                                return <option value={company.id}>{company.companyName}</option>
                                            })
                                        }
                                    </select> */}
                                    <input type="text"
                                    className="form-control"
                                    value={ this.props.sharedStore.companyName }
                                    disabled
                                      />
                                    {/* {
                                        company.error ? <span className={"text-danger text-sm inviteError"}> {company.errMsg}</span> : ''
                                    } */}
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <div className={role.error ? "form-group error-wrapper" : "form-group"}>
                                    <label for="rle">Role *</label>
                                    <select
                                        className={!role.value ? "form-control selection" : "form-control"}
                                        name="role"
                                        id="rle"
                                        value={role.value}
                                        onChange={(e) => this.onInputChange(e)}
                                    >
                                        <option disable hidden value="" >Assign Role</option>
                                        <option value="1">Admin</option>
                                        <option value="2">Developer</option>
                                        <option value="3">Report Creator</option>
                                    </select>
                                    {
                                        role.error ? <span className={"text-danger text-sm inviteError"}> {role.errMsg}</span> : ''
                                    }
                                </div>
                            </Col>
                        </Form.Row>

                        {/* Error Alert Box */}
                        {this.state.isErrorAlertOpen ? (
                            <Suspense fallback="">
                                <AlertBox
                                    alertVisibility={this.state.isErrorAlertOpen}
                                    bodyMessage={errorMessage}
                                    alertVariant="danger"
                                    headerTitle=""
                                    btnCancelLabel=""
                                    isDismissible={true}
                                    onCloseHandler={this.hideErrorDialog} />
                            </Suspense>
                        ) : null}
                    </Form>
                </Modal.Body>

                <div className='inviteDiv'></div>
                <div className='inviteFooter'>
                    <button
                        className='inviteButton btnOrange'
                        onClick={this.onFormSubmit}>Send Invite
                    </button>
                    <div
                        className='inviteCancel'
                        onClick={this.props.modalHandler}>Cancel
                    </div>
                </div>
            </AppModal>
        );
    }
}


export default InviteComponent;
import { observable, action, computed } from "mobx";
import UserService from "../services/userService";
import Utility from "../common/utility";
import config from "../config/config";

const selectCountry = {
  value: "",
  displayValue: "Select Country",
};

const selectState = {
  value: "",
  displayValue: "Select State",
};

const selectCity = {
  value: "",
  displayValue: "Select City",
};

const initialState = {
  loaderCount: 0,
  countryData: [],
  stateData: [],
  countryList: [],
  companyArr: [],
  licenseData: {},
  stateList: [selectState],
  cityList: [selectCity],
  companyId: "",
  companyName: "",
  memberList: [],
  invoiceData: [],
  creditData: [],
  companyMemberId: "",
  token_setting_id: "",
  is_custom_payment_enabled: "",
  companySeriesType: "",
  numberOfLicenses: {},
  creationAllowed: false,
  subLicense: {},
  generateLicenseError: false,
  licenseGenerated: false,
  hotFixList: [],
  companyHotFixList: [],
  versionArr: [],
};

class SharedStore {
  constructor() {
    this.load();
  }

  load() {
    let sharedStoreData = Utility.getData("sharedStore");
    if (
      sharedStoreData &&
      sharedStoreData.countryData &&
      sharedStoreData.countryData.length !== 0
    ) {
      this.countryData = sharedStoreData.countryData;
      this.companyArr = sharedStoreData.companyArr || [];
      this.licenseData = sharedStoreData.licenseData || {};
      this.countryList = this.getCountryList(this.countryData);
      this.stateData = sharedStoreData.stateData || [];
      this.companyId = sharedStoreData.companyId || "";
      this.companyName = sharedStoreData.companyName || "";
      this.memberList = sharedStoreData.memberList || [];
      this.token_setting_id = sharedStoreData.token_setting_id || "";
      this.is_custom_payment_enabled =
        sharedStoreData.is_custom_payment_enabled || "";
      this.invoiceData = sharedStoreData.invoiceData || [];
      this.creditData = sharedStoreData.creditData || [];
      this.companyMemberId = sharedStoreData.companyMemberId || "";
      this.companySeriesType = sharedStoreData.companySeriesType || "";
      this.numberOfLicenses = sharedStoreData.numberOfLicenses || {};
      this.creationAllowed = sharedStoreData.creationAllowed || false;
      this.subLicense = sharedStoreData.subLicense || {};
      this.generateLicenseError = sharedStoreData.generateLicenseError || false;
      this.licenseGenerated = sharedStoreData.licenseGenerated || false;
      this.hotFixList = sharedStoreData.hotFixList || [];
      this.companyHotFixList = sharedStoreData.companyHotFixList || [];
      this.versionArr = sharedStoreData.versionArr || [];
    }
  }

  @observable loaderCount = initialState.loaderCount;
  @observable countryData = initialState.countryData;
  @observable stateData = initialState.stateData;
  @observable countryList = initialState.countryList;
  @observable companyArr = initialState.companyArr;
  @observable licenseData = initialState.licenseData;
  @observable stateList = initialState.stateList;
  @observable cityList = initialState.cityList;
  @observable companyId = initialState.companyId;
  @observable companyName = initialState.companyName;
  @observable memberList = initialState.memberList;
  @observable token_setting_id = initialState.token_setting_id;
  @observable is_custom_payment_enabled =
    initialState.is_custom_payment_enabled;
  @observable invoiceData = initialState.invoiceData;
  @observable creditData = initialState.creditData;
  @observable companyMemberId = initialState.companyMemberId;
  @observable companySeriesType = initialState.companySeriesType;
  @observable numberOfLicenses = initialState.numberOfLicenses;
  @observable creationAllowed = initialState.creationAllowed;
  @observable subLicense = initialState.subLicense;
  @observable generateLicenseError = initialState.generateLicenseError;
  @observable licenseGenerated = initialState.licenseGenerated;
  @observable hotFixList = initialState.hotFixList;
  @observable companyHotFixList = initialState.companyHotFixList;
  @observable versionArr = initialState.versionArr;

  @action setStateList(country = "") {
    let countryData = this.countryData.find((data) => data.Country === country);
    if (countryData) {
      this.stateList = countryData.state.map((data) => {
        return {
          value: data,
          displayValue: data,
        };
      });
      this.stateList.unshift(selectState);
    } else {
      this.stateList = [selectState];
      this.cityList = [selectCity];
    }
  }

  @action getCompanyList() {
    this.showLoader();
    return UserService.getCompanyList()
      .then((res) => {
        this.hideLoader();
        if ((res.responseCode = 200)) {
          this.companyArr = res.data;
        } else {
          this.companyArr = [];
        }
      })
      .catch((err) => {
        this.hideLoader();
        console.log(err);
        this.companyArr = [];
      });
  }

  @action clearLicenseData() {
    this.licenseData = {};
  }
  @action getLicInfo(params) {
    this.showLoader();
    return UserService.fetchLicenseInfo(params)
      .then((res) => {
        if ((res.responseCode = 200)) {
          this.licenseData = res.data;
          this.hideLoader();
        } else {
          this.licenseData = {};
          this.hideLoader();
        }
      })
      .catch((err) => {
        this.hideLoader();
        console.log(err);
        this.licenseData = {};
      });
  }

  @action fetchSubClientInfo(params) {
    return UserService.fetchSubClientInfo(params);
  }

  @action setCityList(state = "") {
    let stateData = this.stateData.find((data) => data.state === state);
    if (stateData) {
      this.cityList = stateData.city.map((data) => {
        return {
          value: data,
          displayValue: data,
        };
      });
      this.cityList.unshift(selectCity);
    } else {
      this.cityList = [selectCity];
    }
  }

  getCountryList(list) {
    let countryList = list.map((data) => {
      return {
        value: data.Country,
        displayValue: data.Country,
      };
    });
    countryList.unshift(selectCountry);
    return countryList;
  }

  @action getCountryData() {
    if (this.countryData.length === 0) {
      UserService.countryData()
        .then((res) => {
          this.countryList = this.getCountryList(res.countries);
          this.countryData = res.countries;
          this.stateData = res.states;
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }

  @action showLoader() {
    this.loaderCount++;
  }

  @action hideLoader() {
    if (this.loaderCount !== 0) {
      this.loaderCount--;
    }
  }

  @action reset() {
    this.loaderCount = initialState.loaderCount;
    // this.countryData = initialState.countryData;
    // this.stateData = initialState.stateData;
    // this.loaderCount = initialState.loaderCount;
    // this.countryData = initialState.countryData;
    // this.stateData = initialState.stateData;
  }

  @computed get isShowLoader() {
    if (this.loaderCount <= 0) {
      return false;
    }
    return true;
  }

  @action setCompanyId(id) {
    this.companyId = id;
  }

  @action setCompanyName(name) {
    this.companyName = name;
  }

  @action setMemberList(list) {
    this.memberList = list;
  }

  @action setTokenId(id) {
    this.token_setting_id = id;
  }

  @action set_payment_enabled(value) {
    this.is_custom_payment_enabled = value;
  }

  @action clearCompanyId() {
    this.companyId = "";
  }

  @action getInvoices(params) {
    return UserService.getInvoiceData(params);
  }

  @action viewDocument(params) {
    return UserService.viewDocument(params);
  }

  @action createInvoice(data, config) {
    return UserService.addInvoice(data, config);
  }

  @action inviteMember(data) {
    return UserService.inviteMember(data);
  }

  @action removeDocument(data) {
    return UserService.removeDocument(data);
  }

  @action checkMember(data) {
    return UserService.checkMember(data);
  }

  @action setInvoiceData(data) {
    this.invoiceData = [...data];
  }
  @action setCreditData(data) {
    this.creditData = [...data];
  }

  @action clearInvoiceData() {
    this.invoiceData = [];
  }
  @action clearCreditData() {
    this.creditData = [];
  }

  @action setCompanyMemberId(id) {
    this.companyMemberId = id;
  }

  @action clearCompanyMemberId() {
    this.companyMemberId = "";
  }

  @action updateAmount(data) {
    return UserService.updateAmount(data);
  }

  @action updateCompanyLicense(data) {
    return UserService.updateCompanyLicense(data);
  }

  @action checkExistingUser(email) {
    return UserService.checkExistingUser(email);
  }

  @action setCompanySeriesType(type) {
    this.companySeriesType = type;
  }

  @action validateFile(data, config) {
    return UserService.validateFile(data, config);
  }

  @action saveCreditMemo(data, config) {
    return UserService.saveCreditMemo(data, config);
  }

  @action fetchCredit(data) {
    return UserService.fetchCreditMemo(data);
  }

  @action getSubLicenseAllowance(params) {
    return UserService.getSubLicenseAllowance(params)
      .then((res) => {
        if ((res.status_code = 200)) {
          this.numberOfLicenses = res;
          this.creationAllowed = res.creation_allowed;
        } else {
          this.numberOfLicenses = {};
          this.creationAllowed = false;
        }
      })
      .catch((error) => {
        console.log(error);
        this.numberOfLicenses = {};
        this.creationAllowed = false;
      });
  }

  @action putSubLicenseAllowance(data) {
    return UserService.putSubLicenseAllowance(data)
      .then((res) => {
        if ((res.status_code = 200)) {
          this.numberOfLicenses = res;
          this.creationAllowed = res.creation_allowed;
        } else {
          this.numberOfLicenses = {};
          this.creationAllowed = false;
        }
      })
      .catch((error) => {
        console.log(error);
        this.numberOfLicenses = {};
        this.creationAllowed = false;
      });
  }

  @action updateSubLicenseMode(data) {
    return UserService.updateSubLicenseMode(data);
  }

  @action setLicenseError(val) {
    this.generateLicenseError = val;
  }

  @action setLicenseGenerated(val) {
    this.licenseGenerated = val;
  }

  @action generateSubLicense(data) {
    this.showLoader();
    return UserService.generateSubLicense(data)
      .then((res) => {
        this.hideLoader();
        if (res.status_code === 200) {
          this.setLicenseGenerated(true);
          this.subLicense = res.data;
          this.getSubLicenseAllowance({ company_id: this.companyId });
          this.getLicInfo({ id: this.companyId });
        }
        if (res.status_code === 400) {
          if (res.message === "SubClient Name Error") {
            this.setLicenseError(true);
          }
          if (res.message === "Internal Server Error") {
            this.getSubLicenseAllowance({ company_id: this.companyId });
          }
        } else {
          this.subLicense = {};
          this.getSubLicenseAllowance({ company_id: this.companyId });
        }
      })
      .catch((error) => {
        console.log(error);
        this.hideLoader();
        this.subLicense = {};
      });
  }

  @action getHotFixList() {
    this.showLoader();
    return UserService.getHotFixList()
      .then((res) => {
        this.hideLoader();
        if (res.status_code === 200) {
          this.hotFixList = res.data;
        } else {
          this.hotFixList = [];
        }
      })
      .catch((error) => {
        console.log(error);
        this.hideLoader();
        this.hotFixList = [];
      });
  }

  @action fetchCompanyHotFixList(hotfix_id, flag = 1) {
    this.showLoader();
    return UserService.fetchCompanyHotFixList({
      hotfix_id: hotfix_id,
      flag: flag,
    })
      .then((res) => {
        this.hideLoader();
        if (res.status_code === 200) {
          this.companyHotFixList = res.data;
        } else {
          this.companyHotFixList = [];
        }
      })
      .catch((error) => {
        console.log(error);
        this.hideLoader();
        this.companyHotFixList = [];
      });
  }

  @action sendHotFix(hotfix_id) {
    return UserService.postHotFix({ hotfix_id: hotfix_id });
  }

  @action removePublicHotFix(hotfix_id) {
    return UserService.removePublicHotFix({ hotfix_id: hotfix_id });
  }

  @action removeAssignedHotFix(hotfix_id, companyArr, flag = 0) {
    return UserService.removeAssignedHotFix({
      hotfix_id: hotfix_id,
      companyArr: companyArr,
      flag: flag,
    });
  }

  @action assignHotFix(hotfix_id, company) {
    return UserService.assignHotFixes({
      hotfix_id: hotfix_id,
      company: company,
    });
  }

  @action deleteHotFix(hotfix_id) {
    return UserService.deleteHotFix({ hotfix_id: hotfix_id });
  }

  @action clearSubLicense() {
    return (this.subLicense = {});
  }

  @action fetchResources(params) {
    return UserService.fetchResources(params);
  }

  @action fetchVersions() {
    return UserService.fetchVersions()
      .then((res) => {
        if (res && res.data) {
          this.versionArr = res.data.version;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export default SharedStore;

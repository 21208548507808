import React, { Component, Suspense } from "react";
import "./ResendInvite.scss";
import { inject, observer } from "mobx-react";

@inject("sharedStore", "userStore")
@observer
class ResendInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sendInvite() {
    console.log("token_type", this.props.token_type);
    const { email, token_type } = this.props;
    this.props.sharedStore.showLoader();
    this.props.userStore
      .sendInvite({ email: email, token_type: token_type })
      .then((res) => {
        this.props.sharedStore.hideLoader();
        if (res.status_code === 200) {
          // this.props.sharedStore.setMemberList(res.data.result);
          this.props.closeInvite();
          this.props.setToastData("success", res.message);
          let params = {
            // token_setting_id: this.props.sharedStore.token_setting_id,
            id: this.props.sharedStore.companyId,
          };
          this.props.userStore
            .getCompanyUserInfo(params)
            .then((res) => {
              this.props.sharedStore.hideLoader();
              if (res.responseCode === 200) {
                this.props.sharedStore.setMemberList(res.data.result);
                this.setState({
                  result: res.data.result,
                });
              }
            })
            .catch((err) => {
              this.props.sharedStore.hideLoader();
              console.log("err", err);
            });
        }
      })
      .catch((err) => {
        this.props.sharedStore.hideLoader();
        console.log("err", err);
        this.props.setToastData("error", err.message);
      });
  }

  render() {
    const { token_type } = this.props;
    return (
      <div className="popup">
        <em class="out"></em>
        <div className="uploadSection">
          {token_type === "registration"
            ? "Re-send Invite?"
            : "Re-send Verification?"}
        </div>
        <div className="resend-button">
          <button
            className="btnOrange resend-popup-btn"
            onClick={() => this.props.closeInvite()}
          >
            No
          </button>
          <button
            className="btnOrange resend-popup-btn"
            onClick={() => this.sendInvite()}
          >
            Yes
          </button>
        </div>
      </div>
    );
  }
}

export default ResendInvite;

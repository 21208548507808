import { autorun } from "mobx";
import LoginStore from "./LoginStore";
import UserStore from "./UserStore";
import SharedStore from "./SharedStore";
import Utility from "../common/utility";

class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.loginStore = new LoginStore(this);
    this.sharedStore = new SharedStore(this);
    autorun((a) => {
      let loginStoreData = Utility.getData("loginStore");
      let sharedStoreData = Utility.getData("sharedStore");
      let userStoreData = Utility.getData("userStore");
      if (!loginStoreData) {
        loginStoreData = {};
      }

      loginStoreData.isUserLogin = this.loginStore.isUserLogin;
      loginStoreData.userEmail = this.loginStore.userEmail;
      loginStoreData.userName = this.loginStore.userName;
      loginStoreData.userType = this.loginStore.userType;
      Utility.setData("loginStore", loginStoreData);

      if (!sharedStoreData) {
        sharedStoreData = {};
      }
      sharedStoreData.countryData = this.sharedStore.countryData;
      sharedStoreData.stateData = this.sharedStore.stateData;
      sharedStoreData.companyId = this.sharedStore.companyId;
      sharedStoreData.invoiceData = this.sharedStore.invoiceData;
      sharedStoreData.companyMemberId = this.sharedStore.companyMemberId;
      sharedStoreData.companyArr = this.sharedStore.companyArr;
      sharedStoreData.token_setting_id = this.sharedStore.token_setting_id;
      sharedStoreData.is_custom_payment_enabled = this.sharedStore.is_custom_payment_enabled;
      sharedStoreData.companyName = this.sharedStore.companyName;
      sharedStoreData.licenseData = this.sharedStore.licenseData;
      sharedStoreData.creditData = this.sharedStore.creditData;
      sharedStoreData.companySeriesType = this.sharedStore.companySeriesType;
      sharedStoreData.numberOfLicenses = this.sharedStore.numberOfLicenses;
      Utility.setData("sharedStore", sharedStoreData);

      if (!userStoreData) {
        userStoreData = {};
      }

      userStoreData.selectedCompany = this.userStore.selectedCompany;
      userStoreData.selectedEnvironment = this.userStore.selectedEnvironment;
      userStoreData.selectedReportingDS = this.userStore.selectedReportingDS;
      userStoreData.searchKeyword = this.userStore.searchKeyword;
      userStoreData.currentPage = this.userStore.currentPage;
      Utility.setData("userStore", userStoreData);
    });
  }
}

const rootStore = new RootStore();

export default rootStore;

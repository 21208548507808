import React from "react";
import { Form } from "react-bootstrap";

const SelectField = props => {
  return (
    <Form.Group>
      {props.label ? <Form.Label>{props.label}</Form.Label> : null}
      <Form.Control
        as="select"
        className={props.fieldClass}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
      >
        {props.selectOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.displayValue}
          </option>
        ))}
      </Form.Control>
      {props.error ? (
        <div className="error-message">{props.errorMessage}</div>
      ) : null}
    </Form.Group>
  );
};

export default SelectField;



import React, { Component } from "react";
import { Modal, Form, Col } from "react-bootstrap";
import AppModal from "../../../UI/AppModal/AppModal";
import Button from "../../../UI/Button/Button";
import { inject, observer } from "mobx-react";

@inject("sharedStore")
@observer
class RemoveNonPublicHotFix extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }

    unlinkFile = ()=>{
        const { selectedHotFixId,parentCompanyId} = this.props
        this.props.sharedStore.showLoader();
        let arr = []
        arr.push(parentCompanyId)
        this.props.sharedStore.removeAssignedHotFix(selectedHotFixId,arr)
        .then((data)=>{
            this.props.sharedStore.hideLoader();
            if (data.status_code === 200) {
                this.props.sharedStore.getHotFixList()
                this.props.sharedStore.fetchCompanyHotFixList(selectedHotFixId)
                this.props.setToastData(
                    "success",
                    data.message
                );
            }
            this.props.modalHandler()
        }).catch((err)=>{
            this.props.sharedStore.hideLoader();
            this.props.setToastData("error", "Internal Server Error");
        })
    }

   

    render() {
        return (
            <AppModal
                showModal={this.props.modalVisibility}
                modalHandler={this.props.modalHandler}
                modalCeneterd={true}
                dialogCustomClass="form-modal custom_build"
                modalSize="md"
                modalBackdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Custom Builds</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Col xs={12} sm={12} md={12}>
                                <div>Are you sure you want unlink {this.props.selectedHotFix} from {this.props.parentCompanyName} ?</div>
                            </Col>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    <Button
                        btnVariant="primary"
                        btnClass="app-btn"
                        clickHandler={this.unlinkFile}
                    >
                        Yes, Unlink
                    </Button>
                    <Button btnVariant="secondary" clickHandler={this.props.modalHandler}>
                        Cancel
          </Button>
                </Modal.Footer>
            </AppModal>
        );
    }
}

export default RemoveNonPublicHotFix;

import React from "react";
import PropTypes from 'prop-types';
import { Alert } from "react-bootstrap";

const AlertBox = props => {
  return (
    <Alert
      show={props.alertVisibility}
      variant={props.alertVariant}
      dismissible={props.isDismissible}
      onClose={props.onCloseHandler}
    >
      {props.headerTitle ? (
        <Alert.Heading>{props.headerTitle}</Alert.Heading>
      ) : null}
      <p className="mb-0">{props.bodyMessage}</p>
    </Alert>
  );
};

AlertBox.propTypes = {
  alertVisibility: PropTypes.bool,
  isDismissible: PropTypes.bool,
  alertVariant: PropTypes.string,
  headerTitle: PropTypes.string,
  bodyMessage: PropTypes.string,
  onCloseHandler: PropTypes.func
}

export default AlertBox;

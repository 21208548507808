import React from 'react';
import PropTypes from 'prop-types';
// import classes from './Image.css';
import { Image as Img } from "react-bootstrap";

const Image = props => {
  return (
    <Img src={props.image} alt={props.alt} className={props.imgClass} />
  );
};

Image.defaultProps = {
  alt:''
}

Image.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  imgClass: PropTypes.string,
}

export default Image;

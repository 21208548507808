import URLS from '../common/api';
import http from '../interceptor';

export default {
  login: params => http.post(URLS.login, params),
  setPassword: params => http.post(URLS.setPassword, params),
  sendOtp: params => http.post(URLS.sendOtp, params),
  verifyOtp: params => http.post(URLS.verifyOtp, params),
  changePassword: params => http.post(URLS.changePassword, params)
};



import React, { Component } from "react";
import { Modal, Form, Col } from "react-bootstrap";
import AppModal from "../../../UI/AppModal/AppModal";
import Button from "../../../UI/Button/Button";
import { inject, observer } from "mobx-react";

@inject("sharedStore")
@observer
class RemovePublicHotFix extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }


    unlinkFile = () => {
        this.props.sharedStore.showLoader();
        return this.props.sharedStore.removePublicHotFix(this.props.selectedHotFixId)
            .then((res) => {
                this.props.sharedStore.hideLoader();
                if (res.status_code === 200) {
                    this.props.sharedStore.getHotFixList()
                    this.props.sharedStore.fetchCompanyHotFixList(this.props.selectedHotFixId)
                    this.props.setToastData(
                        "success",
                        res.message
                    );
                }
                this.props.modalHandler()
            })
            .catch((err) => {
                this.props.sharedStore.hideLoader();
                this.props.setToastData("error", "Internal Server Error");
            });
    }

    render() {
        return (
            <AppModal
                showModal={this.props.modalVisibility}
                modalHandler={this.props.modalHandler}
                modalCeneterd={true}
                dialogCustomClass="form-modal custom_build"
                modalSize="md"
                modalBackdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Col xs={12} sm={12} md={12}>
                                <div>Are you sure you want unlink {this.props.selectedHotFix} from all Izenda 7 series customers?</div>
                                <div className="cus_build_note_txt">Note: File will not be unlinked from customers who were individually sent this file.</div>
                            </Col>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    <Button
                        btnVariant="primary"
                        btnClass="app-btn"
                        clickHandler={this.unlinkFile}
                    >
                        YES, UNLINK
                    </Button>
                    <Button btnVariant="secondary" clickHandler={this.props.modalHandler}>
                        CANCEL
          </Button>
                </Modal.Footer>
            </AppModal>
        );
    }
}

export default RemovePublicHotFix;

import React from "react";
import { DataTable as Table } from "primereact/datatable";
import "./DataTable.scss";

const DataTable = props => {
  return (
    <Table
      ref={props.tableRef}
      value={props.tableData}
      globalFilter={props.globalFilter}
      editable={props.isEditable}
      exportFilename={props.exportFileName}
      selectionMode={props.selectionMode}
      selection={props.selection}
      onSelectionChange={props.onSelectionChange}
      footer={props.tableFooter}
    >
      {props.children}
    </Table>
  );
};

export default DataTable;

import { observable, action } from "mobx";
import LoginService from "../services/loginService";
import Utility from "../common/utility";
import rootStore from "./RootStore";

const initialState = {
  userEmail: "",
  userName : "",
  userType : "",
  isUserLogin : false
}

class LoginStore {
  constructor() {
    this.load();
  }

  load() {
    let loginStoreData = Utility.getData("loginStore");
    if (loginStoreData) {
      this.isUserLogin = loginStoreData.isUserLogin;
      this.userEmail = loginStoreData.userEmail;
      this.userName = loginStoreData.userName;
      this.userType = loginStoreData.userType;
    }
  }

  @observable userName = initialState.userName;
  @observable userType = initialState.userType;
  @observable isUserLogin = initialState.isUserLogin;

  @action login(params) {
    return LoginService.login(params);
  }

  @action setPassword(params) {
    return LoginService.setPassword(params);
  }

  @action changePassword(params) {
    return LoginService.changePassword(params);
  }

  @action sendOtp(params) {
    return LoginService.sendOtp(params);
  }

  @action verifyOtp(params) {
    return LoginService.verifyOtp(params);
  }

  @action logout() {
    this.reset();
    Utility.clearData('accessToken'); // clear the access token from local storage
    Utility.clearData('refreshToken'); // clear the refresh token from local storage
    Utility.clearData('customerFilter'); // clear the filters for customer listing
    Utility.clearData('userId'); // Clear userId on logout
    rootStore.sharedStore.reset();
    rootStore.userStore.reset();
  }

  @action reset() {
    this.isUserLogin = initialState.isUserLogin;
    this.userEmail = initialState.userEmail;
    this.userName = initialState.userName;
    this.userType = initialState.userType;
  }
}

export default LoginStore;

import React, { Component, Suspense } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Icon from "react-icons-kit";
import { download3 } from "react-icons-kit/icomoon/download3";
import { ic_keyboard_arrow_right } from "react-icons-kit/md/ic_keyboard_arrow_right";
import { ic_keyboard_arrow_down } from "react-icons-kit/md/ic_keyboard_arrow_down";
import { ic_delete } from "react-icons-kit/md/ic_delete";
import ShareAllCustomers from "./ShareAllCustomers/ShareAllCustomers";
import Toast from "../../UI/Toast/Toast";
import RemoveCustomBuild from "./RemoveCustomBuild/RemoveCustomBuild";
import RemovePublicHotFix from "./RemovePublicHotFix/RemovePublicHotFix";
import ViewCustomers from "./ViewCustomers/ViewCustomers";
import RemoveNonPublicHotFix from "./RemoveNonPublicHotFix/RemoveNonPublicHotFix";
import Select from "react-select";

// import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
class CustomBuild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      responseTitle: "",
      responseMessage: "",
      companylist: true,
      accordian: new Map(),
      isopen: "close",
      showModal: "",
      selectedHotFix: "",
      selectedHotFixId: "",
      companyName: null,
      parentCompanyName: "",
      parentCompanyId: "",
      isSaveEnabled: false,
      isCancelEnabled: false,
      // selectedOption: null,
      // companyArr: [],
    };
    this.setToastData = this.setToastData.bind(this);
  }

  componentDidMount() {
    this.props.sharedStore.getHotFixList();
    this.props.userStore.getDropdownCompanies();
    // console.log(this.props.userStore);
    // console.log(this.props.userStore.companyList);
  }

  handleChange = (companyName) => {
    this.setState(
      { companyName, isSaveEnabled: true, isCancelEnabled: true },
      () => console.log(`Option selected:`, this.state.companyName)
    );
  };

  openAccordian = (e, versionId, version) => {
    const { accordian } = this.state;
    if (accordian.has(version)) {
      if (!accordian.get(version)) {
        this.props.sharedStore.fetchCompanyHotFixList(versionId);
      }
      accordian.set(version, !accordian.get(version));
    } else {
      this.props.sharedStore.fetchCompanyHotFixList(versionId);
      accordian.set(version, true);
    }
    [...accordian.keys()].forEach((key) => {
      if (key !== version) {
        accordian.set(key, false);
      }
    });

    this.setState({
      accordian,
      companyName: { value: "", label: "" },
    });
  };

  showToast() {
    const { responseType, responseTitle, responseMessage } = this.state;
    this.growl.show({
      severity: responseType,
      summary: responseTitle,
      detail: responseMessage,
      life: 2200,
    });
  }

  setToastData = (toastType, toastMessage) => {
    this.setState(
      {
        responseType: toastType,
        responseTitle: toastType,
        responseMessage: toastMessage,
      },
      () => this.showToast()
    );
  };

  toggleModal = (e) => {
    this.setState({
      showModal: "",
    });
  };

  // changeModal = (modalNo,companyArr)=>{
  //     console.log('modal',modalNo,companyArr)
  //     this.setState({
  //         showModal: modalNo,
  //         companyArr : companyArr
  //     })

  // }

  setModal = (e, item, modalNo, data = "") => {
    if (modalNo === 5) {
      this.setState({
        parentCompanyName: data.company_name,
        parentCompanyId: data.company,
      });
    }
    this.setState({
      showModal: modalNo,
      selectedHotFixId: item.id,
      selectedHotFix: item.value,
    });
  };

  // onInputChange = (e) => {
  //   this.setState({
  //     companyName: e.target.value,
  //   });
  // };

  cancelInput = () => {
    this.setState({
      companyName: { value: "", label: "" },
      isSaveEnabled: false,
      isCancelEnabled: false,
    });
    // if (this.state.companyName.length > 0) {
    //   this.setState({
    //     companyName: "",
    //   });
    // }
  };

  assignHotFix = (e, item) => {
    if (this.state.companyName && this.state.companyName.value) {
      console.log(item);
      this.props.sharedStore.showLoader();
      this.props.sharedStore
        .assignHotFix(item.id, this.state.companyName.value)
        .then((res) => {
          this.props.sharedStore.hideLoader();
          if (res.status_code === 201 || res.status_code === 200) {
            this.props.sharedStore.getHotFixList();
            this.props.sharedStore.fetchCompanyHotFixList(item.id);
            this.setToastData("success", res.message);
            this.setState({
              companyName: { value: "", label: "" },
              isSaveEnabled: false,
              isCancelEnabled: false,
            });
          } else {
            this.setToastData("error", res.message);
          }

          this.toggleModal();
        })
        .catch((err) => {
          console.log("err", err);
          this.props.sharedStore.hideLoader();
          this.setToastData("error", "Internal Server Error");
        });
    }
  };

  unlinkSingleFile = (e, company_id) => {
    console.log("company_id", company_id);
  };

  fixArr = (arr, list) => {
    let newArr = [...arr];
    let indexes = [];
    for (let i = 0; i < list.length; i++) {
      const index = arr.findIndex(
        (item) =>
          item.label === list[i].company_name ||
          item.value === list[i].company_name
      );
      indexes.push(index);
    }
    for (let i = 0; i < indexes.length; i++) {
      newArr.splice(indexes[i], 1);
    }
    return newArr;
  };

  render() {
    const { hotFixList, companyHotFixList } = this.props.sharedStore;
    const { companiesList } = this.props.userStore;
    const { companyName, isSaveEnabled, isCancelEnabled } = this.state;

    return (
      <section className="user-section">
        <Container>
          <div className="custom_build_wrapper">
            <div className="headrer_link">
              <a href="/dashboard" className="goback">
                Back
              </a>
            </div>
            <div className="build_containt">
              <div className="build_headertxt">Custom Builds</div>
              <div className="build_headitem odd_item">
                <div className="head_itemtxt">File Name</div>
                <div className="head_itemtxt">Size</div>
                <div className="head_itemtxt">Date</div>
                <div className="head_itemtxt"># Shared</div>
                <div className="head_itemtxt">Download</div>
              </div>
              {hotFixList &&
                hotFixList.versionArr &&
                hotFixList.versionArr.map((item, i) => {
                  return (
                    <div className="build_headitem build_item_list" key={i}>
                      <div className="head_itemvalue">
                        <span
                          onClick={(e) =>
                            this.openAccordian(e, item.id, item.value)
                          }
                          className={
                            this.state.accordian.get(item.value)
                              ? "angledown-icons"
                              : "angleleft-icons"
                          }
                        >
                          <Icon
                            size={30}
                            icon={ic_keyboard_arrow_right}
                            className="left_iocns"
                          />
                        </span>
                        {item.value}
                      </div>
                      <div className="head_itemvalue">__ MB</div>
                      <div className="head_itemvalue">DD/MM/YYYY </div>
                      <div className="head_itemvalue">
                        {hotFixList.total_count - 200 <= item.shared
                          ? hotFixList.total_count
                          : item.shared}
                      </div>
                      <div className="head_itemvalue">
                        <a
                          className="app-btn btn-xl btn btn-primary custombuilddwn"
                          target="_blank"
                        >
                          <Icon
                            size={16}
                            icon={download3}
                            className="downloadIcon"
                          />
                          Download
                        </a>
                      </div>
                      {this.state.accordian.get(item.value) ? (
                        <div>
                          <div className="cus_build_wrapper">
                            <div className="cus_bld_header">
                              <div className="cus_bld_head_items">
                                Customers with Access
                              </div>
                              <div className="cus_bld_head_items">
                                Share File
                              </div>
                              <div className="cus_bld_head_items build_dlt_txt">
                                Delete File
                              </div>
                            </div>

                            <div className="cus_build_items_wrapper">
                              <div className="cus_build_container">
                                {hotFixList.total_count - 200 <= item.shared ? (
                                  <div className="cus_build_items">
                                    <span className="com_build_name">All</span>
                                    <span
                                      onClick={(e) => this.setModal(e, item, 3)}
                                      className="build_delete_icons"
                                    >
                                      {" "}
                                      <Icon
                                        size={30}
                                        icon={ic_delete}
                                        className="downloadIcon"
                                      />
                                    </span>
                                  </div>
                                ) : null}
                                {companyHotFixList &&
                                  companyHotFixList.length <= 5 &&
                                  companyHotFixList
                                    .slice(0, 5)
                                    .map((val, index) => {
                                      return (
                                        <div
                                          className="cus_build_items"
                                          key={index}
                                        >
                                          <span className="com_build_name">
                                            {val.company_name}
                                          </span>
                                          <span
                                            className="build_delete_icons"
                                            onClick={(e) =>
                                              this.setModal(e, item, 5, val)
                                            }
                                          >
                                            {" "}
                                            <Icon
                                              size={30}
                                              icon={ic_delete}
                                              className="downloadIcon"
                                            />
                                          </span>
                                        </div>
                                      );
                                    })}
                                {companyHotFixList.length > 5 ? (
                                  <div className="vie_txt_cuatomer">
                                    <a
                                      onClick={(e) => this.setModal(e, item, 6)}
                                    >
                                      View Customers
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="cus_build_items_wrapper">
                              {/* <input
                                type="text"
                                name="companyName"
                                onChange={(e) => this.onInputChange(e)}
                                value={this.state.companyName}
                                className="build_com-name"
                                placeholder="[type company name]"
                              /> */}
                              <Select
                                value={companyName}
                                options={this.fixArr(
                                  companiesList,
                                  companyHotFixList
                                )}
                                onChange={this.handleChange}
                                className="build_com-name"
                                placeholder="[type company name]"
                              />
                              <div className="build_act_btn">
                                <button
                                  onClick={(e) => this.assignHotFix(e, item)}
                                  className={
                                    isSaveEnabled
                                      ? "app-btn btn-xl btn btn-warning"
                                      : "app-btn btn-xl btn btn-warning disableBtn"
                                  }
                                  disabled={!isSaveEnabled}
                                >
                                  Save
                                </button>
                                <button
                                  onClick={this.cancelInput}
                                  className={
                                    isCancelEnabled
                                      ? "app-btn btn-xl btn btn-warning"
                                      : "app-btn btn-xl btn btn-warning disableBtn"
                                  }
                                  disabled={!isCancelEnabled}
                                >
                                  Cancel
                                </button>
                              </div>
                              <button
                                onClick={(e) => this.setModal(e, item, 1)}
                                disabled={
                                  hotFixList.total_count - 200 <= item.shared
                                }
                                className={
                                  hotFixList.total_count - 200 <= item.shared
                                    ? "app-btn btn-lg btn btn-warning af_btn_save cus_btn_len"
                                    : "app-btn btn-lg btn btn-primary before_btn_save cus_btn_len"
                                }
                              >
                                Share with all 7 customers
                              </button>
                            </div>
                            <div className="cus_build_items_wrapper">
                              <span
                                onClick={(e) => this.setModal(e, item, 2)}
                                className="build_delete_text"
                              >
                                {" "}
                                Delete File
                              </span>
                            </div>
                            <ShareAllCustomers
                              modalHandler={(e) => this.toggleModal(e)}
                              modalVisibility={
                                this.state.showModal === 1 ? true : false
                              }
                              setToastData={this.setToastData}
                              selectedHotFix={this.state.selectedHotFix}
                              selectedHotFixId={this.state.selectedHotFixId}
                              modalClass="iz-modal invite-modal"
                            />
                            <RemoveCustomBuild
                              modalHandler={(e) => this.toggleModal(e)}
                              modalVisibility={
                                this.state.showModal === 2 ? true : false
                              }
                              setToastData={this.setToastData}
                              selectedHotFix={this.state.selectedHotFix}
                              selectedHotFixId={this.state.selectedHotFixId}
                              modalClass="iz-modal invite-modal"
                            />
                            <RemovePublicHotFix
                              modalHandler={(e) => this.toggleModal(e)}
                              modalVisibility={
                                this.state.showModal === 3 ? true : false
                              }
                              setToastData={this.setToastData}
                              selectedHotFix={this.state.selectedHotFix}
                              selectedHotFixId={this.state.selectedHotFixId}
                              modalClass="iz-modal invite-modal"
                            />
                            <RemoveNonPublicHotFix
                              modalHandler={(e) => this.toggleModal(e)}
                              modalVisibility={
                                this.state.showModal === 5 ? true : false
                              }
                              setToastData={this.setToastData}
                              selectedHotFix={this.state.selectedHotFix}
                              selectedHotFixId={this.state.selectedHotFixId}
                              parentCompanyId={this.state.parentCompanyId}
                              parentCompanyName={this.state.parentCompanyName}
                              modalClass="iz-modal invite-modal"
                            />
                            <ViewCustomers
                              modalHandler={(e) => this.toggleModal(e)}
                              changeModal={this.changeModal}
                              modalVisibility={
                                this.state.showModal === 6 ? true : false
                              }
                              setToastData={this.setToastData}
                              selectedHotFix={this.state.selectedHotFix}
                              selectedHotFixId={this.state.selectedHotFixId}
                              modalClass="iz-modal invite-modal"
                            />
                            {/* <ConfirmationModal
                                                        modalHandler={(e) => this.toggleModal(e)}
                                                        companyArr = {this.state.companyArr}
                                                        modalVisibility={this.state.showModal === 7 ? true : false}
                                                        setToastData={this.setToastData}
                                                        selectedHotFix={this.state.selectedHotFix}
                                                        selectedHotFixId={this.state.selectedHotFixId}
                                                        modalClass="iz-modal invite-modal"

                                                    /> */}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          </div>
        </Container>
        <Toast toastRef={(el) => (this.growl = el)} />
      </section>
    );
  }
}

export default CustomBuild;

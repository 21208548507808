import React, { Component } from "react";
import { Modal, Form, Col } from "react-bootstrap";
import UserModel from "../../../models/UserModel";
import Utility from "../../../common/utility";
import errorMessage from "../../../common/errorMessages";
import AppModal from "../../UI/AppModal/AppModal";
import InputField from "../../UI/InputField/InputField";
import SelectField from "../../UI/SelectField/SelectField";
import Button from "../../UI/Button/Button";
import Global from "../../../common/global";

class AddUserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: new UserModel()
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onInputChange(e) {
    let valid = Utility.checkInput(e.target.name, e.target.value);
    if (valid) {
      let form = this.state.form;
      form[e.target.name].value = e.target.value;
      form[e.target.name].error = false;
      form[e.target.name].errorMessage = "";
      this.setState({ form });
    }
  }

  checkFormValidation() {
    let validated = this.state.form.validation();
    if (validated.isValid) {
      return true;
    } else {
      this.setState({ form: validated.form });
    }
  }

  onFormSubmit(e) {
    e.preventDefault();
    if (this.checkFormValidation()) {
      let form = this.state.form;
      const params = form.getUserParams();
      console.log('params',params)
      this.props.sharedStore.showLoader();
      this.props.userStore
        .addUser(params)
        .then(res => {
          this.props.sharedStore.hideLoader();
          if (res.responseCode === 200) {
            this.props.modalHandler(true);
            this.props.setToastData('success',res.message);
          } else {
            this.props.setToastData('error',res.message);
          }
        })
        .catch(err => {
          this.props.sharedStore.hideLoader();
          console.log("err", err);
          this.props.setToastData('error',errorMessage.networkError);
        });
    }
  }

  render() {
    const { form } = this.state;
    let { countryList, stateList, cityList } = this.props.sharedStore;

    return (
      <AppModal
        showModal={this.props.modalVisibility}
        modalHandler={this.props.modalHandler}
        modalCeneterd={true}
        dialogCustomClass="form-modal internal_invitation"
        // modalSize="lg"
        modalBackdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">Add Internal User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
            <Col xs={12} sm={12} md={6}>
                <InputField
                  label="First Name"
                  type="text"
                  class=""
                  name="firstName"
                  placeholder=""
                  onChange={this.onInputChange}
                  value={form.firstName.value}
                  error={form.firstName.error}
                  errorMessage={form.firstName.errorMessage}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <InputField
                  label="Last Name"
                  type="text"
                  class=""
                  name="lastName"
                  placeholder=""
                  onChange={this.onInputChange}
                  value={form.lastName.value}
                  error={form.lastName.error}
                  errorMessage={form.lastName.errorMessage}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <InputField
                  label="Email"
                  type="text"
                  class=""
                  name="email"
                  placeholder=""
                  onChange={this.onInputChange}
                  value={form.email.value}
                  error={form.email.error}
                  errorMessage={form.email.errorMessage}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <InputField
                  label="Company Name"
                  type="text"
                  class=""
                  name="company"
                  placeholder=""
                  onChange={this.onInputChange}
                  value={form.company.value}
                  disabled={form.company.readOnly}
                  error={form.company.error}
                  errorMessage={form.company.errorMessage}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <SelectField
                  label="User Type"
                  fieldClass=""
                  name="userType"
                  onChange={this.onInputChange}
                  selectOptions={Global.internalUserType}
                  value={form.userType.value}
                  error={form.userType.error}
                  errorMessage={form.userType.errorMessage}
                />
              </Col>
              
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button btnVariant="secondary" clickHandler={this.props.modalHandler}>
            Close
          </Button>
          <Button btnVariant="primary" clickHandler={this.onFormSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </AppModal>
    );
  }
}

export default AddUserComponent;

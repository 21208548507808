import React from "react";
import { Form } from "react-bootstrap";
import Button from "../Button/Button";
import Image from '../Image/Image';
import passwordHidden from "../../../assets/images/paswd-hidden.png";
import passwordVisible from "../../../assets/images/paswd-visible.png";

const PasswordField = props => {
  return (
    <Form.Group>
      <div className={props.inputWrapperClass}>
        {props.label ? <Form.Label>{props.label}</Form.Label> : null}
        <Form.Control
          type={props.type}
          className={props.class}
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
          maxLength={props.maxLength ? props.maxLength : ""}
          disabled={props.disabled}
        />
        <Button
          btnClass="password-btn"
          btnType="button"
          clickHandler={props.btnHandler}
          btnVariant="link"
        >
          <Image
            image={props.type === "text" ? passwordVisible : passwordHidden}
            alt="password-img"
          />
        </Button>
        {props.error ? (
          <div className="error-message">{props.errorMessage}</div>
        ) : null}
      </div>
    </Form.Group>
  );
};

export default PasswordField;

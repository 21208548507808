import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import rootStore from "./stores/RootStore";

ReactDOM.render(
  <Provider
    rootStore={rootStore}
    userStore={rootStore.userStore}
    loginStore={rootStore.loginStore}
    sharedStore={rootStore.sharedStore}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
 * @Author: Pulkit
 * @Date: 2019-03-19 11:25:10
 * @Last Modified by: Pulkit
 */

const URLS = {
  login: `login`,
  userList: "viewUsers",
  addUser: "insertUser",
  deleteUser: "deleteUser",
  editUser: "editUser",
  dropdownLists: "filterValues",
  countryData: "filterArea",
  exportData: "export",
  changePassword: "changePassword",
  sendOtp: "sendOtp",
  setPassword: "setNewPassword",
  verifyOtp: "verifyOtp",
  refreshToken: "refreshToken",
  editUserDetails: "userDetails",
  getCompanyUser: "fetchMember",
  fetchInvoiceData: "fetchInvoice",
  addInvoice: "createInvoice",
  inviteMember: "inviteMember",
  checkMember: "checkMember",
  getCompanyList: "fetchCompany",
  removeDocument: "deleteDocument",
  fetchLicenseInfo: "licInfo",
  fetchSubClientInfo: "subClientInfo",
  updateAmount: "license/updateAmount",
  updateCompanyLicense: "updateCompanyLicense",
  checkExistingUser: "checkExistingUser",
  sendInvite: "sendInvite",
  paymentToggle: "updateCustomToggle",
  validateFile: "validateFile",
  creditMemo: `creditMemo`,
  fetchCreditMemo: "fetchCreditMemo",
  subLicenseAllowance: "subLicenseAllowance",
  updateSubLicenseMode: "updateSubLicenseMode",
  createLicense: "createLicense",
  fetchHotFixes: "fetchHotFixes",
  postHotFix: "shareAll",
  removePublicHotFix: "removePublicHotFix",
  deleteHotFix: "deleteHotFix",
  assignHotFixes: "assignHotFixes",
  removeAssignedHotFix: "removeAssignedHotFix",
  fetchResources: "fetchResources",
};

export default URLS;

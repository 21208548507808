import React from "react";
import PropTypes from 'prop-types';
import { Growl } from 'primereact/growl';

const Toast = props => {
  return (
    <Growl
      ref={props.toastRef}
      severity={props.toastVariant}
      summary={props.toastTitle}
      detail={props.toastMessage}
    />
  );
};

Toast.propTypes = {
  toastVariant: PropTypes.string,
  toastTitle: PropTypes.string,
  toastMessage: PropTypes.string
}

export default Toast;

import axios from 'axios';
import Config from "./config/config";
import Utility from './common/utility';
import TokenFreeApis from './common/tokenFreeApi';
import URLS from './common/api';
import rootStore from './stores/RootStore';


/**
 * make custom axios request.
 */
const http = axios.create({
  baseURL: Config.baseURL,
  timeout: Config.timeout,
  // headers: { Pragma: "no-cache" },
});

let isRefreshing = false;

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    const resetToken = await Utility.getToken('refreshToken'); // Your own mechanism to get the refresh token to refresh the JWT token
    if (!resetToken) {
      goToLogin();
      // We can't refresh, throw the error anyway
      return Promise.reject(error);
    }
    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
    /* We need to add the request retry to the queue
    since there another request that already attempt to
    refresh the token */
      addSubscriber(access_token => {
        errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
        let request = errorResponse.config
        resolve(axios({
          method: request.method,
          url : request.url,
          data: request.data ? JSON.parse(request.data): '',
          headers: { 
            Authorization: request.headers.Authorization
          }
        }))
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await axios({
        method: 'post',
        baseURL: `${Config.baseURL}`,
        url:  `${URLS.refreshToken}`,
        data: {
          refresh: resetToken // Just an example, your case may vary
        }
      });
       
      if (!response.data) {
        return Promise.reject(error);
      }
      const newToken = response.data.access;
      Utility.setToken('accessToken', response.data.access);
      Utility.setToken('refreshToken', response.data.refresh);
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newToken);
    }    
    return retryOriginalRequest.then((value)=>{
        return value.data
      }).catch((err)=>{
        Utility.clearAllData()
        return Promise.reject(err);
      })
  } catch (err) {
    goToLogin();
    return Promise.reject(err);
  }
}


function onAccessTokenFetched(access_token) {
	// When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

/**
 * 
 * @param {*} headers api response header
 * checking refresh token is expired or not.
 */
const isRefreshTokenExpired = (headers) => {
  // console.log('history', history)
  return false;
}

const goToLogin = () => {
  rootStore.loginStore.logout();
  window.location = '';
}

/**
 * 
 * @param {*} headers api response header
 * checking access token is expired or not.
 */
const isAccessTokenExpired = (headers) => {
  if (Utility.stringToBoolean(headers['access-token-expired']) || Utility.stringToBoolean(headers['invalid-token'])) {
    return true;
  }
  return false;
}

/**
 * Redirect to login if user is not authenticate i.e api send 401 status.
 */
const redirectUnAuth = (response) => {
  if (response.status === 401 || response.status === 403) {
    Utility.clearAllData(); // Clear the localStorage.
    // return router.push({
    //   name: '/',
    // }); // Go to the Login Page
  }
};

/**
 * Check url needs token or not.
 */
const checkToken = (url) => {
  const isTokenRequired = !TokenFreeApis.find(item => item === url);
  if (isTokenRequired) {
    return true;
  }
  return false;
};


/**
 * Interceptor for api request.
 */
http.interceptors.request.use((request) => {
  // GET Token and add token to request if any api needed.
  if (checkToken(request.url)) {
    request.headers.Authorization = `Bearer ${Utility.getToken('accessToken')}`; // eslint-disable-line
  }
  // console.log('request', request)
  return request;
}, error => Promise.reject(error));

/**
 * Interceptor for api response.
 */
http.interceptors.response.use((response) => {
  // Check for unauthorized request.
  // redirectUnAuth(response);
  // Check access token is expired or not.
  // return http(originalRequest);

  if (isRefreshTokenExpired()) {
    console.log('logout the user');
    goToLogin();
  } else if (isAccessTokenExpired(response.headers)) {
    // hit api for getting the refreshed access token.
    goToLogin();
    // return getRefreshedToken(originalRequest);
  } else {
    // Set tokens.
    if (response.status === 200 && response.data.access) {
      Utility.setToken('accessToken', response.data.access);
    }

    if (response.status === 200 && response.data.refresh) {
      Utility.setToken('refreshToken', response.data.refresh);
    }
    
    return response.data;
  }
}, (error) => {
  const originalRequest = error.config;
  if(originalRequest.url === 
    `${URLS.refreshToken}` && error.response.status === 401){
      return Promise.reject(error);
    }
  if(error.response.status === 401){
        return resetTokenAndReattemptRequest(error)
  }

  if (error.message === 'Network Error') {
    // network error
    const networkError = {
      errorCodeList: ['NETWORK'],
    };
    return Promise.reject(networkError);
  }
   if (error.message === `timeout of ${Config.timeout}ms exceeded`) {
    // timeout error
    const timeoutError = {
      errorCodeList: ['TIMEOUT'],
    };
    return Promise.reject(timeoutError);
  }
  console.log('errrrrrrrrrrrrrrr',error)
  return Promise.reject(error);
});

export default http;

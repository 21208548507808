import React from 'react';
import { Pagination } from "react-bootstrap";

const getPageItems = (totalPages, currentPage, onPageChange) => {
    let items = [];
    for (let count = 1; count <= totalPages; count++) {
        items.push(
            <Pagination.Item
                key={count}
                active={count === currentPage}
                onClick={() => onPageChange(count)}
            >
                {count}
            </Pagination.Item>
        )
    }
    return items;
}

const CustomePagination = (props) => (
    <Pagination className={props.wrapperClass}>
        {/* <Pagination.First  /> */}
        <Pagination.Prev disabled={props.currentPage === 1} onClick={() => props.onPageChange(props.currentPage - 1)}/>
        {getPageItems(props.totalPages, props.currentPage, props.onPageChange)}
        <Pagination.Next disabled={props.currentPage === props.totalPages} onClick={() => props.onPageChange(props.currentPage + 1)}/>
        {/* <Pagination.Last /> */}
    </Pagination>
);

export default CustomePagination;
/*
 * @Author: Pulkit
 * @Date: 2019-03-20 11:25:10
 * @Last Modified by: Pulkit
 */

const global = {
  regex: {
    email: /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
    alpha: /^[a-zA-Z\s.]*$/,
    alphaOnly: /^[a-zA-Z\s]*$/,
    numeric: /^[0-9]*$/,
    alphaNumeric: /^[a-zA-Z0-9]*$/,
    name: /^[a-zA-Z\s.]{2,}$/,
    description: /^[a-zA-Z\s.]{5,}$/,
    otp: /^[0-9]{6}$/,
    phoneNumber: /^[+]{0,1}[0-9]*$/
  },
  internalUserType: [
    { value: "", displayValue: "Select User Type" },
    { value: "Admin", displayValue: "Admin" },
    { value: "Super User", displayValue: "Super User" }
  ],
  reportingDS: [
    "MySQL",
    "SQL Server",
    "Elastic Search",
    "PostgreSQL"
  ],
  userType: {
    staff:  "staff",
    admin: "admin",
    superUser: "super_user"
  },
  pageSize: 10,
  uploadedInvoiceType: ['pdf']
};

export default global;

import React, { Component } from "react";
import "./footer.scss";

class FooterComponent extends Component {
  render() {
    return (
      <footer>
        <div className="container">Copyright 2021 Izenda, Inc.</div>
      </footer>
    );
  }
}

export default FooterComponent;

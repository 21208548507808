import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import Logo from "../../UI/Logo/Logo";
import Toast from "../../UI/Toast/Toast";
import Navigation from "../../Navigation/Navigation";
import Utility from '../../../common/utility';
import { Icon } from 'react-icons-kit';
import {longArrowLeft} from 'react-icons-kit/fa/longArrowLeft'
import "./header.scss";
const AddUser = React.lazy(() =>
  import("../../../containers/UserManagement/AddUser")
);
const ChangePassword = React.lazy(() =>
  import("../../../containers/Authentication/ChangePassword")
);
const InviteMember = React.lazy(() =>
  import("../../UserManagement/Members/Invite/InviteComponent")
);

class HeaderComponent extends Component {
  constructor(props) {
     super(props);
     this.state = {
      isAddUserModalOpen: false,
      isChangePasswordModalOpen: false,
      responseType: '',
      responseTitle: '',
      responseMessage: '',
      isInviteMemberModalOpen: false,
    };
    this.changeNavLink = this.changeNavLink.bind(this);
    this.toggleAddUserModal = this.toggleAddUserModal.bind(this);
    this.toggleChangePasswordModal = this.toggleChangePasswordModal.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.showToast = this.showToast.bind(this);
    this.setToastData = this.setToastData.bind(this);
  }

  componentDidMount() {
    this.changeNavLink(this.props.location.pathname);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.changeNavLink(nextProps.location.pathname);
    }
  }

  changeNavLink(currentRoute) {
    if (currentRoute === "/dashboard") {
      // this.props.loginStore.isUserLogin = false;
    }
  }

  toggleAddUserModal(isUserAdded = false) {
    // if (isUserAdded === true) {
    //   this.props.userStore.getUsers();
    // }
    this.setState({
      isAddUserModalOpen: !this.state.isAddUserModalOpen
    });
  }

  toggleChangePasswordModal() {
    this.setState({
      isChangePasswordModalOpen: !this.state.isChangePasswordModalOpen
    });
  }

  setToastData(toastType,toastMessage) {
    this.setState({
      responseType: toastType,
      responseTitle: toastType,
      responseMessage: toastMessage
    }, () => this.showToast())
  }

  showToast() {
    const { responseType, responseTitle, responseMessage } = this.state;
    this.growl.show({ severity: responseType, summary: responseTitle, detail: responseMessage, life: 1600 });
  }

  logoutUser() {
    this.props.sharedStore.clearInvoiceData();
    this.props.history.push("/");
    this.props.loginStore.logout();
  }
  toggleInviteMemberModal = () => {
    this.setState({
      isInviteMemberModalOpen: !this.state.isInviteMemberModalOpen
    });
  }
  render() {
    return (
      <header>
        <div className="full-widthcontainer">
          <Navbar expand="lg">
            <Navbar.Brand href='/dashboard'>
              <Logo class="app-logo" alt="logo" imgClass="img-fluid"/>
              
            </Navbar.Brand>
            {/* Menu toggle Button for small screens (tab and mobile) */}
            {
              this.props.loginStore.isUserLogin ? <Navbar.Toggle aria-controls="basic-navbar-nav" /> : null
            }
            {
              this.props.location.pathname == "/account" ?  <div className="backicon"> <Icon className="user_icons" size="25px" icon={longArrowLeft} /><a href="/dashboard"> Back</a>
              <span className="company_name">{ this.props.sharedStore.companyName }</span>
            </div> : null
            }
          
            <Navbar.Collapse id="basic-navbar-nav">
              <Navigation
                isAuthenticated={this.props.loginStore.isUserLogin}
                addUserHandler={this.toggleAddUserModal}
                inviteMemberHandler={this.toggleInviteMemberModal}
                changePasswordHanlder={this.toggleChangePasswordModal}
                logout={this.logoutUser}
                userName={this.props.loginStore.userName}
                userType={this.props.loginStore.userType}
              />
            </Navbar.Collapse>
          </Navbar>

          {/* Invite Member Modal */}
          {this.state.isInviteMemberModalOpen ? (
            <Suspense fallback="">
              <InviteMember
                modalHandler={this.toggleInviteMemberModal}
                modalVisibility={this.state.isInviteMemberModalOpen}
                setToastData={this.setToastData}
                modalClass="iz-modal invite-modal"
              />
            </Suspense>
          ) : null}
          
          {/* Add User Modal */}
          {this.state.isAddUserModalOpen ? (
            <Suspense fallback="">
              <AddUser
                modalHandler={this.toggleAddUserModal}
                modalVisibility={this.state.isAddUserModalOpen}
                setToastData={this.setToastData}
              />
            </Suspense>
          ) : null}

          {/* Change Password Modal */}
          {this.state.isChangePasswordModalOpen ? (
            <Suspense fallback="">
              <ChangePassword
                modalHandler={this.toggleChangePasswordModal}
                modalVisibility={this.state.isChangePasswordModalOpen}
                setToastData={this.setToastData}
              />
            </Suspense>
          ) : null}
        </div>

        {/* Error, Success Message Taost */}
        <Toast toastRef={(el) => this.growl = el} />
        {/* <Toast toastRef={(el) => this.growl = el} toastVariant={this.state.responseType} toastTitle={this.state.responseTitle} toastMessage={this.state.responseMessage} /> */}
      </header>
    );
  }
}

export default withRouter(HeaderComponent);

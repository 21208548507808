import React, { Component } from "react";

const Toggle = (props) => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        value={props.value}
        checked={props.value}
        onChange={props.onChange}
      />
      <span className="slider round" />
    </label>
  );
};

export default Toggle;
